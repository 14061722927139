
.drawer-sidebar {
  .ant-drawer-mask {
    left    : 0;
    right   : 0;
    top     : 0;
    bottom  : 0;
    position: fixed;
  } 

  .ant-drawer-content {
    background-color: @nav-dark-bg;
    color           : @nav-dark-text-color;

    &-content-wrapper {
      width: @sidebar-width  !important;
    }
  }

  .layout-sider-header {
    background-color: rgba(0, 0, 0, 0.3);
    box-shadow      : none;
    padding         : 10px 15px;
    height          : 72px;
    position        : relative;
    z-index         : 1;
    .flex-display(flex, row, nowrap);
    .align-items(center);
    justify-content: center;

    .logo {
      img {
        max-width: 100px;
      }
    }

    .linebar {
      display: none;
    }
  }

  .sidebar-content {
    .layout-sider-scrollbar {
      height: calc(100vh - 72px - 145px) !important;
    }

    .ant-menu {
      .icon {
        min-width     : 14px;
        margin-right  : 20px;
        display       : inline-block;
        vertical-align: middle;
        font-size     : 18px;
      }

      &-item {
        &-selected {
          background-color: transparent !important;

          a {
            color: @secondary-color  !important;
          }
        }
      }

      &-title-content {
        font-size: 15px;

        a {
          color      : #fff;
          display    : flex;
          align-items: center;

        }
      }

      &-submenu {
        color: #fff;

        &-arrow {
          opacity: 1 !important;

          &:before,
          &:after {
            background-color: #fff !important;
          }
        }

        .ant-menu-sub {
          background-color: transparent !important;

          .ant-menu-item {
            padding-left: 65px !important;
          }
        }
      }
    }
  }

  @media(max-width:575px) {
    .layout-sider-header {
      height: 51px;
    }
    .sidebar-content{
      .ant-menu{
        &-item{
          height: 34px;
          line-height: 34px;
        }
      }
     
    }
  }
}