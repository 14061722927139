.ant-checkbox-wrapper {
  
  .ant-checkbox {
    &-checked {
      .ant-checkbox-inner {
        border-color    : @layout-header-background-dark;
        background-color: @layout-header-background-dark;
      }
      &:after{
           border-radius:0px;
           border-color    : @layout-header-background-dark;
      }
    }

    &-indeterminate {
      .ant-checkbox-inner {
        &:after {
          top             : 50%;
          left            : 50%;
          background-color: @layout-header-background-dark;
        }
      }
    }

    &:hover {
      .ant-checkbox-inner {
        border-color: @layout-header-background-dark;
      }

    }
  }

  .ant-checkbox-inner {
    border-radius: 0px;
    border-width : 2px;
    border-color : #868686;

    &:after {
      top: 5px;left: 2px;
    }
  }
}

.customCheckbox{
  display: inline-block;
  input { position: absolute;left: 0; z-index: 1;width: 100% !important; opacity:0; cursor: pointer;
    + span{
      position: relative; padding-left: 28px; color:#545454; cursor: pointer;
      &:after{
        content:""; height: 16px; width: 16px; border:2px solid #868686; position: absolute; left: 0; top: 0;
      }
    }
    &:checked + span{
      &:after{
        border-color:@layout-header-background-dark; background:@layout-header-background-dark;
      }
      &:before{
        content: ' '; position: absolute;border: 2px solid @white-color; width: 6px;
        height: 10px; border-top: 0;border-left: 0;transform: rotate(45deg);left: 5px; top:1px; z-index: 1;
      }
    }
  }
  &:not(:last-child){margin-right:25px;}
}
.receiveAlerts{
  span{
    font-family:'Montserrat-Medium'; color:#545454; margin-left: 5px;
  }
  input[type='checkbox']{height: 16px; width: 16px;}
}