.viewPage{
    margin: -32px -32px 0;
    .serialNum{
        width: 70px;
    }
    .userView{
        background-color: @layout-header-background-dark;
        color:@white-color;
        &-top{
            padding: 30px;
            display: flex;
            align-items: center;   
            flex-wrap: wrap;        
        }
        &-right{
            margin-left: auto;
            padding-left: 40px;
            .actionList{
                display: flex;
                align-items: center;
                list-style: none;
                margin: 0 -10px;
                padding: 0;
                li{
                    padding: 0 10px;
                }
                .link {
                    color: @white-color;
                    font-size: 20px;
                    &:hover, &:active, &:focus{
                        color: @primary-color;
                    }
                }
            }
        }
        .user-info {
            display: flex;
            .user-image{
                height: 100px;
                width: 100px;
                min-width: 100px;
                border-radius: 5px;
                overflow: hidden;
                border: 1px solid #dbdfea;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 30px;
            }
            .name {
                font-size: 22px;
                color:@white-color;
                font-family: @font-bd;
                margin-bottom: 7px;
            }
            .address {
                font-family: @font-md;
                margin: 0 0 7px;
            }
            &-list{
                display: flex;
                align-items: center;
                list-style: none;
                flex-wrap: wrap;
                margin: 0 -10px 0;
                padding: 0;
                li{
                    padding: 0 10px;
                    margin-bottom: 10px;
                    .anticon{
                        margin-right: 5px;
                    }
                }
            }
            .editOption{
                .link{font-size: 20px; color:@white-color; margin-left: 20px;}
            }
        }
        &-bottom{
            border-top: 1px solid rgba(255, 255, 255, 0.35);
            padding: 20px 20px 0;           
            .row{
                .column{
                    flex-grow: 1;
                    margin-bottom: 20px;
                    &:not(:last-child){
                        border-right: 1px solid rgba(255, 255, 255, 0.35);
                    }
                }
            }
            &-nurse{
                .row{
                    .column{
                       max-width: 20%;
                    }
                }
            }
            .sub-label{
                display: block;
                font-size: 13px;
                margin-bottom: 5px;
            }
            .sub-text{
                display: block;
                font-size: 16px;
                font-family: @font-md;
                &.breakWord{
                    word-break: break-word;
                }
            }
        }
    }
    .privilegeList{
        li{
            font-family: @font-sm;
        }
    }
    .ant-tabs-ink-bar{
        background: @secondary-color;
    }
    .ant-tabs-nav {
        padding: 0 1.5rem;
        margin-bottom: 0;
        background-color: #2e2c80;
        .ant-tabs-tab{
            padding: 18px 0;
            min-width: 150px;
            justify-content: center;
            .ant-tabs-tab-btn{
                color: @white-color;
                font-family: @font-md;
            }
            &-active, &:hover{
                .ant-tabs-tab-btn{
                    color: @secondary-color;
                }
            }
        }
    }
    .subtitle{
        font-family: @font-bd;
        font-size: 16px;
        margin-bottom: 30px;
    }
    .content{
        &-inner{
            padding: 30px;
            table{
                td, th{
                    white-space: nowrap;
                }
            }
        }
    }
    .infoBox{
        margin-bottom: 30px;
        &.user-image{
            display: flex;
            img{height: 80px; width: 80px; margin-right: 15px; border:1px solid @black-color;}
        }
        &-form{
            height: calc(100% - 50px);
            .ant-form{
                display: flex;
                flex-direction: column;
                height: 100%;
            }
            &-btn{
                margin-top: auto;
                text-align: center;
            }
        }
        &-wrap{
            padding: 30px;
            background-color:@white-color;
            height: 100%;
        }
        .label{
            display: block;
            font-size: 13px;
            margin-bottom: 5px;
            font-family: @font-md;
            color: @text-color;
        }
        .value{
            color: @header-text-color;
            font-family: @font-sm;
            &.breakWord{
                word-break: break-word;
            }
            &-list{
                padding: 0;
                margin: 0;
                list-style: none;
                li{
                    &:not(:last-child){
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
    @media (max-width: 1599.98px) {
        .userView{
            &-top{
                padding: 25px;
            }
            .user-info{
                .user-image{
                    height: 90px;
                    width: 90px;
                    min-width: 90px;
                    margin-right: 25px;
                }
                .name{
                    font-size: 20px;
                }
            }
        }
        .ant-tabs-nav{
            .ant-tabs-tab{
                min-width: 130px;
                padding: 15px 0;
            }
        }
        .infoBox{
            margin-bottom: 25px;
            &-wrap{
                padding: 25px;
            }
        }
        .subtitle{
            margin-bottom: 25px;
        }
        .content{
            &-inner{
                padding: 25px;
            }
        }
    }

    @media (max-width: 1399.98px) {
        margin: -22px -22px 0;
    }

    @media(max-width:991.98px) {
        .userView{
            &-top{
                padding: 20px;
            }
            .user-info{
                .user-image {
                    margin-right: 20px;
                    height: 80px;
                    width: 80px;
                    min-width: 80px;
                }
                .name{
                    font-size: 18px;
                }
                .editOption{
                    .link{font-size: 18px; margin-left: 15px;}
                }
            }
            &-bottom{
                padding: 15px 15px 0;
                .row{
                    .column{
                        margin-bottom: 15px;
                    }
                }
                &-nurse{
                    .row{
                        .column{
                            max-width: 30%;
                        }
                    }
                }
                .sub-label{
                    font-size: 12px;
                    margin-bottom: 3px;
                }
                .sub-text{
                    font-size: 14px;
                }
            }
        }
        .content{
            &-inner{
                padding: 20px;
            }
        }
        .infoBox{
            margin-bottom: 20px;
            &-wrap{
                padding: 20px;
            }
        }
        .ant-tabs-nav{
            .ant-tabs-tab{
                min-width: 100px;
            }
        }
        .subtitle {
            margin-bottom: 15px;
        }
    }

    @media(max-width:767.98px) {
        .userView{
            &-right, &-left{
                width: 100%;
            }
            &-right{
                margin-bottom: 15px;
                order: 1;
                padding-left: 0;
                .actionList{
                    justify-content: flex-end;
                    li{
                        .ant-btn{
                            height: 30px;
                            line-height: 27px;
                        }
                    }
                    .link{
                        font-size: 18px;
                    }
                }
            }
            &-left{
                order: 2;
            }
            &-bottom {
                .row{
                    .column{
                        flex: 0 0 33.33%;
                    }
                }
                &-nurse{
                    .row{
                        .column{
                            max-width: 50%;
                        }
                    }
                }
            }
        }
        .privilegeList{
            li{
                padding: 10px 0;
                font-size: 13px;
            }
        }
        .content{
            &-inner{
                padding: 15px;
            }
        }
        .infoBox{
            margin-bottom: 15px;
            &-wrap{
                padding: 15px;
            }
        }
        .subtitle{
            font-size: 15px;
        }
        .ant-tabs-nav{
            .ant-tabs-tab{
                min-width: 90px;
                .ant-tabs-tab-btn{
                    font-size: 13px;
                }
            }
        }
        .ant-table-thead {
            > tr{
                > th{
                    font-size: 13px;
                }
            }
        }
        .ant-table-tbody{
            > tr{
                > td{
                    font-size: 13px;
                }
            }
        }
    }

    @media(max-width:575.98px) {
        margin: -20px -15px 0;
        .infoBox{
            margin-bottom: 15px;
            &-wrap{
                padding: 15px;
                margin-bottom: 15px;
                height: auto;
            }
            .value{
                font-size: 13px;
            }
        }
        .userView{
            &-top{
                padding: 15px;
            }
            .user-info{
                .user-image {
                    margin-right: 15px;
                    height: 70px;
                    width: 70px;
                    min-width: 70px;
                }
                .name{
                    font-size: 16px;
                }
                .address{
                    font-size: 13px;
                    line-height: 20px;
                }
                &-list{
                    font-size: 13px;
                }
                .ant-badge-count{
                    font-size: 11px;
                }
                .editOption{
                    .link{font-size:16px;}
                }
            }
        }
        .ant-tabs-nav {
            padding: 0 1rem;
            .ant-tabs-tab {
                min-width: auto;
            }
        }
        .subtitle{
            font-size: 14px;
            margin-bottom: 10px;
        }
    }

    @media(max-width:399.98px) {
        .userView{
            .user-info{
                display: block;
                text-align: center;
                .user-image{
                    margin: 0 auto 15px;
                }
                &-list{
                    justify-content: center;
                    li{
                        width: 100%;
                    }
                }
                .editOption{
                    justify-content:center;
                }
            }
            &-bottom .row .column {
                flex: 0 0 50%;
            }
        }
    }

}