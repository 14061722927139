.calendarPage{ margin-bottom: 20px;
    .clinicList{ background:#fff; box-shadow:0 0 20px rgb(38 38 38 / 7%); margin-bottom: 1px;
        ul{padding: 0; margin: 0; list-style:none; display: flex;overflow: auto;
            a{padding: 10px 15px; border-right: 1px solid #E2E2E2; max-width: 250px; display: block; text-align:center;
                img{width: 36px; height: 36px; min-width: 36px; margin-bottom: 5px; border-radius:50%;}
                h6{margin-bottom: 5px;font-size: 14px;font-weight: 600;}
                p{ color: #535353;font-size: 12px;}
                &.active{background:#038fde;
                    h6,p{color:#fff;}
                }
            }
        }
    }
    .calendarBox{
        .ant-picker-calendar{
            border-radius:0; box-shadow:0 0 20px rgba(#262626,0.07);
            .ant-picker-calendar-header{padding: 15px;
                .ant-radio-button-wrapper{display: none;
                    &.ant-radio-button-wrapper-checked{background:@secondary-color; border-color:@secondary-color; color:@white-color;}
                    &:before{display: none;}
                }
            }
            .ant-picker-panel{padding: 15px;}
            .ant-picker-content{
                thead{
                    th{
                        padding: 10px 20px;
                    }
                }
                tbody{
                    td{
                        border:1px solid #e8e8e8;
                        padding: 30px 20px;
                        &.ant-picker-cell-today{
                            background:@primary-color !important;
                        }
                        &.ant-picker-cell-selected{
                            .ant-picker-cell-inner {
                                color:#545454;
                            }
                        }
                        .ant-picker-calendar-date-value{position: relative; z-index: 1;}
                        .ant-picker-cell-inner{position:static;}
                        .ant-picker-calendar-date-content{
                            p{margin: 0; font-weight:700; color:@white-color;
                                &:after{content:""; position: absolute; left: 0; top: 0; height: 100%; width: 100%; background:#67BB6A;}
                                span{position: relative; z-index: 1;}
                            }
                        }
                        
                    }
                }
                .ant-picker-cell{
                    .ant-picker-cell-inner{
                       background:transparent !important;
                        &:before{display: none;}
                    }
                    &.ant-picker-cell-today{
                        .ant-picker-cell-inner{
                             color:@white-color !important;
                        }   
                    }
                }
            }
        }
    }
    @media screen and (max-width:1439px) {
        .calendarBox{
            .ant-picker-calendar{
                .ant-picker-content{
                    thead{
                        th{
                            padding: 10px 12px;
                        }
                    }
                    tbody{
                        td{
                            padding: 20px 12px;
                            .ant-picker-calendar-date-content{
                                p{font-size:13px;}
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: @screen-lg-max) {
        .calendarBox{
            .ant-picker-calendar{
                .ant-picker-calendar-header{padding: 10px;}
                .ant-picker-panel{padding: 15px 10px;}
                .ant-picker-content{
                    thead{
                        th{
                            padding: 10px 10px;
                        }
                    }
                    tbody{
                        td{
                            padding: 20px 10px;
                            .ant-picker-calendar-date-content{
                                p{font-size:12px;}
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: @screen-md-max) {
        .calendarBox{
            .ant-picker-calendar{
                .ant-picker-content{
                    thead{
                        th{
                            padding: 10px 5px;
                        }
                    }
                    tbody{
                        td{
                            padding: 15px 5px;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: @screen-sm-max) {
        .calendarBox{
            .ant-picker-calendar{
                .ant-radio-group{display: none;}
                .ant-picker-content{
                    tbody{
                        td{
                            padding: 10px 5px;
                            .ant-picker-calendar-date-content{
                                p{white-space:normal; word-break:break-all; line-height:normal;}
                            }
                        }
                    }
                }
            }
        }
        .clinicList{
            ul{
                a{padding: 5px 10px; display: flex; align-items: center; text-align:left;
                    img{width: 30px; height: 30px; min-width: 30px; margin-bottom: 0px; margin-right: 8px;}
                    h6{font-size:13px; margin-bottom: 3px;}
                }
            }
        }
    }
}
