.tableFilter {
    box-shadow   : 0 3px 12px 1px rgba(11, 49, 117, 0.15);
    min-width    : 360px;
    max-width    : 360px;
    border-radius: 4px;
    background   : #fff;
    position     : absolute;
    right        : 0;
    top          : 37px;
    z-index      : 1;

    &__head {
        padding      : 0.75rem 1.25rem;
        border-bottom: 1px solid #e5e9f2;
        font-size    : 16px;
        color        : #364a63;
        font-weight  : bold;
    }

    &__body {
        padding: 1.25rem 1.25rem 0.25rem;
    }

    &__footer {
        display        : flex;
        justify-content: space-between;
        padding        : 0.75rem 1.25rem;
        border-top     : 1px solid #e5e9f2;

        .ant-btn-link {
            padding: 0px;
        }
    }
}

.filterSidebar {
    .ant-drawer-header {
        padding: 15px 15px;        
    }
    .ant-drawer-title{
        font-family:@font-sm;
    }
    &_body{
        padding:15px;flex-grow: 1;
        
    }
    .ant-form{
        height:100%;display: flex;
        flex-direction: column;
    }
    &_footer {
        display        : flex;
        justify-content: space-between;
        padding        : 0.75rem 1.25rem;
        border-top     : 1px solid #e5e9f2;
        flex-shrink: 0;

        .ant-btn-link {
            padding: 0px;
        }
    }
}