.bookAppointment{ margin-bottom: 30px;
    &__content{background:@white-color; padding: 30px; box-shadow:0 1px 5px 0 rgba(@black-color,0.01); margin-bottom: 20px;
        &--singleField{padding-top:50px;padding-bottom:50px;}
    }
    .stepHead{background:@white-color; padding:20px 30px; border-bottom:1px solid #e8e8e8;
        h4{margin-bottom: 0; font-size:18px; font-weight:600;}
    }
    .timeStep{
        &__calendar{
            .ant-picker-calendar{
                border-radius:0; box-shadow:0 0 20px rgba(#262626,0.07);
                .ant-picker-calendar-header{padding: 15px;
                    .ant-radio-button-wrapper{
                        &.ant-radio-button-wrapper-checked{background:@secondary-color; border-color:@secondary-color; color:@white-color;}
                        &:before{display: none;}
                    }
                }
                .ant-picker-panel{padding: 15px;}
                .ant-picker-content{
                    thead{
                        th{padding: 5px;}
                    }
                    .ant-picker-cell{
                        .ant-picker-cell-inner{
                            height: 45px; width: 45px; border-radius:50%; display: flex; align-items:center; justify-content:center;
                            &:before{display: none;}
                        }
                        &.ant-picker-cell-today{
                            .ant-picker-cell-inner{
                                background:@secondary-color !important; color:@white-color !important;
                            }   
                        }
                        &.ant-picker-cell-selected{
                            .ant-picker-cell-inner{
                                background:@layout-header-background-dark !important; color:@white-color !important;
                            }   
                        }
                    }
                }
                .ant-radio-group{display: none;}
            }
        }
        .timeslotBox{
            &__top{margin-bottom: 20px;
                h4{font-weight:600;}
                span{font-size:12px;}
            }
            h6{margin-bottom: 15px;font-weight:600;}
        }
        .slotWrapper{max-height:250px;overflow: hidden; overflow-y:auto; padding-right: 10px;
            .ant-empty{color:red;}
            .ant-radio-group{width: 100%;}
            .ant-form-item-explain{
                &.ant-form-item-explain-error{margin-top: 10px;}
            }
        }
        .timeslot{
            margin: 0 -15px; flex-wrap:wrap;
            &__list{
                padding: 0 15px; flex: 1 1 16.666%; max-width: 16.666%;
                &:not(:last-child){
                    margin-bottom: 15px;
                }
                .ant-radio-wrapper{
                    border: 1px solid #737374; border-radius: 4px; padding:10px; width: 100%; display: flex; justify-content:center;
                    color:#737374; font-size: 14px; cursor:pointer; margin-bottom: 0; text-align: center;
                    &.ant-radio-wrapper-checked{
                        color: @white-color; background:@secondary-color; border-color: @secondary-color;
                    }
                    .ant-radio{display: none;
                        + * {padding: 0;}
                    }
                    &.ant-radio-wrapper-disabled{
                        border-color:rgba(0, 0, 0, 0.25); cursor:not-allowed;
                    }
                }
            }
        }
        .communicationMode{margin-top: 30px;
            .ant-radio-group{display: flex; justify-content:center;
                span{padding: 0; margin: 0;
                    &.ant-radio{display: none;}
                }
            }
            &__list{
                .ant-radio-wrapper{
                    height: 55px; width: 55px; background:#E2E9F1; border-radius:50%; display: flex; align-items:center; justify-content:center;
                    color:@black-color; font-size:22px; line-height:0;
                    .ico-video1{font-size:18px;}
                    &-checked{background:@secondary-color; color:@white-color;}
                }
                &:not(:last-child){margin-right: 30px;}
                label{margin: 0;}
            }
            .ant-form-item-explain.ant-form-item-explain-error{
                margin-top: 10px; text-align:center;
            }
        }
    }
    .requested{
        em{font-size: 90px; color:@secondary-color; margin-bottom: 20px;}
        h2{margin-bottom: 20px; font-size:30px;}
        h3{font-size: 16px; margin-bottom: 30px;
            span{color:@primary-color;}
        }
    }
    .questionnaire{ margin-bottom:0;
        h4,h5{font-weight:600;}
        h5{margin-bottom: 15px;}
        hr {
            margin: 20px 0;
            margin-left: -30px;
            width: calc(100% + 60px);
        }
    }
    .payment{
        &__card{
            background-image: url('../../images/card-bg.png'); background-repeat: no-repeat; background-position:bottom center;
            max-width: 570px; margin: 0 auto; padding:30px; border-radius:7px; background-size:cover;
            &__box{ padding: 20px; background:@white-color; border-radius:5px;}
        }
        .insuranceBox{max-width: 570px; margin: 20px auto 0;}
        .paymentOption{
            position: relative; padding-right: 100px;
            &__img{position: absolute;right: 0; top: 0;}
        }
        .conditionBox{
            .ant-form-item-control{text-align:center;}
        }
    }
    @media (max-width:1680px){
        .timeStep{
            .timeslot{
                margin: 0 -8px;
                &__list{
                    padding: 0 8px;
                    .ant-radio-wrapper{padding: 10px 7px;}
                    &:not(:last-child){
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
    @media (max-width:1439px){
        .timeStep{
            &__calendar{
                .ant-picker-calendar{
                    .ant-picker-calendar-header,.ant-picker-panel{padding: 10px 15px;}
                    .ant-picker-content{
                        .ant-picker-cell{
                            .ant-picker-cell-inner{height:40px; width:40px;}
                        }
                    }
                }
            }
            .timeslotBox{
                &__top{margin-bottom:15px;}
            }
            .timeslot{
                &__list{
                    flex: 1 1 20%; max-width: 20%;
                }
            }
            .communicationMode{margin-top: 20px;
                &__list{
                    .ant-radio-wrapper{
                        height: 50px; width: 50px; font-size:20px;
                        .ico-video1{font-size:16px;}
                    }
                    &:not(:last-child){margin-right: 20px;}
                }
            }
        }
    }
    @media screen and (max-width: @screen-lg-max) {
        margin-bottom:20px;
        .stepHead{padding:15px 20px;}
        &__content{padding: 20px;
            &--singleField{padding-top:30px;padding-bottom:30px;}
        }
        .timeStep{
            &__calendar{margin-bottom:25px;}
            .timeslot{
                &__list{
                    flex: 1 1 25%; max-width: 25%;
                    .ant-radio-wrapper{padding: 8px 7px;}
                }
            }
        }
        .questionnaire{
            hr {margin:15px 0; margin-left: -20px; width: calc(100% + 40px); }
        }
        .requested{
            em{font-size: 80px;}
            h2{font-size:26px;}
            h3{margin-bottom: 20px;}
        }
    }
    
    @media screen and (max-width: @screen-sm-max) {
        .stepHead{padding:15px;
            h4{font-size:16px;}
        }
        &__content{padding: 15px;
            &--singleField{padding-top:15px;padding-bottom:15px;}
        }
        .questionnaire{
            hr {margin-left: -15px; width: calc(100% + 30px); }
        }
        .requested{
            em{font-size: 65px; margin-bottom: 15px;}
            h2{font-size:20px; margin-bottom: 10px;}
            h3{margin-bottom: 15px;}
        }
        .payment{
            h2{font-size:17px;}
            &__card{ padding: 15px;}
        }
    }
    @media screen and (max-width: @screen-xs-max) {
        .timeStep{
            &__calendar{margin-bottom:20px;
                .ant-picker-calendar{
                    .ant-picker-panel{padding: 10px 0;}
                    .ant-picker-content{
                        .ant-picker-cell{
                            .ant-picker-cell-inner{height:30px; width:30px; font-size:12px;}
                        }
                    }
                    &-header{
                        .ant-select{width: 45%;}
                    }
                }
            }
            .timeslot{
                &__list{
                    flex: 1 1 33.33%; max-width:33.33%;
                }
            }
            .communicationMode{margin-top: 15px;
                &__list{
                    .ant-radio-wrapper{
                        height: 45px; width: 45px; font-size:18px;
                        .ico-video1{font-size:15px;}
                    }
                }
            }
        }
        .payment{
            &__card{ 
                
                &__box{padding: 10px;}
            }
            .paymentOption{
                padding-right: 0;
                &__img{display: none;}
            }
        }
    }
    @media (max-width:374px){
        .timeStep{
            .timeslot{
                &__list{
                    flex: 1 1 50%; max-width:50%;
                }
            }
        }
    }
}
.appointmentStep{
    .ant-steps{
        .ant-steps-item{padding-left: 0;
            .ant-steps-item-icon{position: relative;z-index: 1; margin: 0;}
            &.ant-steps-item-active,&.ant-steps-item-finish{
                .ant-steps-item-icon{background:@layout-header-background-dark; border-color:@layout-header-background-dark;
                    .ant-steps-icon{color:@white-color;}
                }
                &:after{background:@layout-header-background-dark !important;}
            }
            &:not(:last-child){
                &:after{content:""; position: absolute;left: 0; top: 16px; height: 1px; width: 100%; background: rgba(0, 0, 0, 0.25);}
            }
        }
        @media screen and (max-width: @screen-xs-max) {
            margin-top: 20px;
        }
    }
}