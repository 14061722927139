.pageHead {
    display        : flex;
    justify-content: space-between;
    margin-bottom  : 25px;
    align-items    : center;

    &_left {
        span {
            font-family: @font-md;
        }
    }

    h1 {
        margin-bottom: 0;
        font-size    : 22px;
        font-family  : @font-sm;
    }

    &_right {
        ul {
            padding   : 0;
            list-style: none;
            margin    : 0;

            li {
                display : inline-block;
                position: relative;

                .ant-btn {
                    margin: 0;
                }

                &:not(:last-child) {
                    margin-right: 15px;
                }

                .ant-dropdown-trigger {
                    font-size : 22px;
                    width     : 30px;
                    height    : 34px;
                    text-align: center;

                    em {
                        top     : 5px;
                        position: relative;
                    }
                }
            }
        }
    }

    @media screen and (max-width: @screen-lg-max) {
        h1 {
            font-size: 20px;
        }
    }

    @media screen and (max-width: @screen-md-max) {
        h1 {
            font-size: 18px;
        }
    }

    @media screen and (max-width: @screen-sm-max) {
       &_left{
            span{
                font-size:12px;
            }
        }
        &_right {
            ul {
                li {
                    &:not(:last-child) {
                        margin-right: 8px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: @screen-xs-max) {
        margin-bottom: 20px;

        h1 {
            font-size: 16px;
        }

    }
}

.headDropdown {
    border-radius: 3px;

    .ant-dropdown-menu-item {
        a {
            font-family: @font-md;
            font-size  : 13px;

            span {
                margin-right: 10px;
            }
        }
    }
}