.videocallPage{ position: relative;
    &__head{background:#E2E9F1; padding:15px; margin-bottom: 20px;
        ul{display: flex; margin: 0; padding: 0;
            li{list-style:none;
                &:not(:last-child){margin-right: 20px;}
                a{font-weight:600; color:@layout-header-background-dark;}
            }
        }
    }
    .videoScreen{
        position: relative; border:2px solid @layout-header-background-dark; height:calc(100vh - 230px); overflow: hidden;
        video{object-fit: cover;}
        &__header{position:absolute; left: 0; top: 0; padding: 20px; z-index: 1; width: 100%;
            .timer {
                font-size: 16px;color: @white-color;background-color: rgba(0,0,0,0.2);
                padding: 9px 20px; border-radius: 30px; text-align: center;
            }
            .btnMaximize{
                height: 48px; width: 48px; border-radius: 50%; color: @white-color;
                background-color: rgba(0,0,0,0.2);font-size: 24px; align-items: center; justify-content:center;
            }
        }
        .control__panel {
            display: flex; justify-content: center;  align-items: center;
            position: absolute; bottom: 40px; width: 100%; padding: 0 15px;
            .btn-circle {
                height: 56px; width: 56px; border-radius: 50%;
                background-color: rgba(0, 0, 0, 0.3); font-size: 24px; border:0 !important;
                text-align: center; color: @white-color; display: flex; align-items: center; justify-content:center;
                &:not(:last-child) { margin-right: 20px;}
                &.phone-btn {background-color: #f73132; height: 64px; width: 64px; font-size: 30px;}
                .ico-video1{font-size:16px;}
                &.addOption{ background:@primary-color;
                    i{line-height: 0;}
                }
                &.active{
                    background:@primary-color;
                }
            }
            .callOption{
                position: absolute; right: -65px; bottom: 80px; transition: all 0.5s ease;
                ul{
                    margin: 0; padding: 0;
                    li{list-style:none;
                        &:not(:last-child){margin-bottom: 7px;}
                        i{line-height: 0;}
                    }
                }
                &.showOption{
                    right: 35px;
                }
            }
        }
        .callReciever{
            position: absolute;left: 2px;bottom:2px; height: 120px; width: 120px;border:2px solid @layout-header-background-dark;
            video{object-fit:cover;}
        }
    }
    .rightScreen{
        position: relative; margin-bottom: 15px;
        &__header{
            background:@layout-header-background-dark; padding: 10px 15px;
            a{color:@secondary-color; font-size:18px;
            + a{margin-left: 15px;}
            }
        }
        &__video{
            height: 210px; border:2px solid @layout-header-background-dark;
            video{object-fit: cover;}
        }
    }
    .callingMessage{
        position: absolute; left: 0; right: 0; background-color: rgba(@black-color,0.8); height: 100%; width: 100%;
        display: flex; align-items: center; justify-content: center; color:@white-color; text-align:center;
    }
    @media screen and (max-width: @screen-lg-max){
        .videoScreen{
            &__header{padding:15px;
                .timer {
                    font-size: 15px; padding: 8px 15px; 
                }
                .btnMaximize{
                    height:40px; width:40px;font-size: 22px;
                }
            }
            .control__panel {
                bottom: 30px;
                .btn-circle {
                    height: 50px; width: 50px; font-size: 22px;
                    &.phone-btn { height: 55px; width: 55px; font-size: 26px;}
                    .ico-video1{font-size:15px;}
                }
            }
            .callReciever{
                height: 100px; width: 100px;
            }
        }
        .rightScreen{
            &__header{padding: 10px;
                a{font-size:16px;
                + a{margin-left: 10px;}
                }
            }
            &__video{height: 200px;}
        }
    }
    @media screen and (max-width: @screen-sm-max){
        .videoScreen{
            .control__panel { bottom: 20px;
                .btn-circle {
                    height: 40px; width: 40px; font-size: 19px;
                    &.phone-btn { height: 45px; width: 45px; font-size: 22px;}
                    .ico-video1{font-size:13px;}
                    &:not(:last-child){margin-right: 15px;}
                }
            }
            .callReciever{
                height: 70px; width: 70px;
            }
        }
        .rightScreen{
            &__header{
                h4{font-size:15px;}
                a{font-size:14px;}
            }
            &__video{height: 180px;}
        }
    }
    @media screen and (max-width: @screen-xs-max){
        .videoScreen{height:calc(100vh - 135px);
            .control__panel { bottom: 15px;
                .btn-circle {
                    height: 35px; width: 35px; font-size: 18px;
                    &.phone-btn { height: 40px; width: 40px; font-size: 20px;}
                    .ico-video1{font-size:12px;}
                    &:not(:last-child){margin-right: 10px;}
                }
            }
            .callReciever{
                height: 60px; width: 60px;
            }
        }
        .rightScreen{ margin-top:15px; margin-bottom: 0;}
    }
    .audioCallIcon{display: none;}
    &.audioCall{
        video{
            background-color:#6AC6B4;
        }
        .audioCallIcon{position: absolute; left: 50%; top: 45%; transform:translate(-50%,-45%); display: block;
            img{max-width:130px;}
        }
    }
}
