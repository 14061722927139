@font-face {
  font-family: 'Montserrat-Regular';
  src: url('Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
  url('Montserrat-Regular.woff') format('woff'); 
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url('Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
  url('Montserrat-Medium.woff') format('woff');
 
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('Montserrat-SemiBold.woff') format('woff');
 
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
  url('Montserrat-Bold.woff') format('woff');

  font-weight: normal;
  font-style: normal;
}

