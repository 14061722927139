.ant-layout-sider {
  background-color: #fff;
  .transition(all 0.2s ease);
  flex     : 0 0 auto !important;
  max-width: none !important;
  min-width: 10px !important;
  width    : @sidebar-width  !important;
  overflow : hidden;

  @media screen and (max-width: (@screen-xl-min + 166px)) {
    width: (@sidebar-width - 40) !important;

  }
  &.collapsed-sidebar {
    width    : 0 !important;
    min-width: 0 !important;
  }

  // @media screen and (max-width: @screen-md-max) {
  //   width    : 0 !important;
  //   min-width: 0 !important;
  // }

  .layout-sider-header {
    background-color: @layout-header-background-dark;
    box-shadow      : none;
    padding         : 10px 30px 10px 70px;
    height          : @layout-header-height;
    position        : relative;
    z-index         : 1;
    .flex-display(flex, row, nowrap);
    .align-items(center);

    .logo {
      img {
        max-width: 120px;
      }
    }

    .linebar {
      position  : absolute;
      left      : 15px;
      z-index   : 1;
      top       : 10px;
      transition: all 0.3s ease-out;

      .icon-btn {
        width        : 40px;
        height       : 40px;
        border-radius: 2px;

        display        : flex;
        flex-direction : row;
        flex-wrap      : wrap;
        justify-content: center;
        align-items    : center;
        cursor         : pointer;
      }
    }
    .patientDetail{max-width:100%;
      h5{color:@primary-color; font-weight: 600; font-size: 16px; margin: 0; word-break: break-all;}
    }
  }

  .sidebar-content {
    .layout-sider-scrollbar {
      height: calc(100vh - 60px) !important;
    }

    .ant-menu {
      background-color: #fff;

      .icon {
        min-width     : 14px;
        margin-right  : 20px;
        display       : inline-block;
        vertical-align: middle;
        font-size     : 20px;
      }



      &-title-content {
        font-size  : 15px;
        font-family: @font-md;

        a {
          color        : #000 !important;
          text-overflow: ellipsis;
          overflow     : hidden;


        }
      }

      &-item {
        &-selected {
          background-color: transparent !important;

          a {
            color: @secondary-color  !important;
          }
        }
        a{
          &.active{
            color: @secondary-color  !important;
          }
        }
      }

      &-submenu {
        color: #000;

        &-arrow {
          opacity: 1 !important;

          &:before,
          &:after {
            background-color: #000 !important;
          }
        }

        .ant-menu-sub {
          background-color: transparent !important;

          .ant-menu-item {
            padding-left: 65px !important;
            height      : 26px;
            line-height : 26px;
          }
        }
      }
    }
  }

  &-collapsed {
    width: @sidebar-mini-drawer-width  !important;
    .layout-sider-header {
      .logo {
        display: none;
      }
    }

    .sidebar-content {
      .ant-menu {
        &-item {
          padding-left: 26px !important;
        }

        &-title-content {
          a ,span{
            span {
              max-width: 0;
              display  : inline-block;
              opacity  : 0;
            }
          }
        }

        &-submenu {
          &-title {
            padding-left: 26px !important;
          }

          &-arrow {
            display: none;
          }

          span {
            span {
              span {
                max-width: 0;
                display  : inline-block;
                opacity  : 0;
              }
            }
          }
        }

      }
    }
  }
@media screen and (max-width: (@screen-xl-min + 166px)) {
  .sidebar-content{
    .ant-menu{
      .ant-menu-item{padding-left: 15px !important;}
      .ant-menu-submenu{
        .ant-menu-submenu-title{padding-left: 15px !important;}
        .ant-menu-sub{
          .ant-menu-item{padding-left: 50px !important;}
        }
      }
      .ant-menu-title-content{font-size:14px;}
      .icon{margin-right: 10px;}
    }
    
  }
}
  @media(max-width:1199.98px) {
    position: fixed; left: -100%; top: 0;height:100%; z-index: 11;
    .layout-sider-scrollbar{background:@white-color;}
    .layout-sider-header {
      padding-top: 5px;
      .linebar { left: 7px;
        .icon-btn{font-size:30px;}
      }
    }
    .sidebar-overlay {
      position: fixed; left: -100%; height: 100%; width: 100%; top: 0; background: rgba(@black-color,0.7); transition:all 0.2s ease;
    }
  }
  @media screen and (max-width: @screen-xs-max) {
    .layout-sider-header {
      height: 50px;padding:5px 20px 9px 55px;
      .linebar { top: 4px;}
    }
    .sidebar-content{
      .layout-sider-scrollbar{height:calc(100vh - 50px) !important;}
    }
  }
}

.ant-menu-inline-collapsed {
  &-tooltip {
    .icon {
      display: none;
    }

    a {
      color: #fff;
    }
  }
}

div.ant-menu-submenu {
  &-popup {
    .ant-menu {
      background-color: @layout-header-background-dark  !important;
      padding-top     : 8px !important;
      padding-bottom  : 8px !important;

      &-item {
        height     : 30px !important;
        line-height: 30px !important;

        a {
          color: #fff !important;

          &:hover {
            color: @secondary-color  !important;
          }
        }

        &-selected {
          background-color: transparent !important;

          a {
            color: @secondary-color  !important;
          }
        }
      }
    }
  }
}
@media(max-width:1199.98px) {
  .showSidebar{
    .ant-layout-sider{left: 0;
      .sidebar-overlay {left: 0;}
    }
  }
}