/*Form Styles*/
.ant-form {
  &-item {
    &-label {
      label {
        font-family: @font-md;
        color      : #000;
      }
    }

    &-explain {
      font-size  : 12px;
      font-family: @font-md;
    }

    &-with-help {
      margin-bottom: 20px;
    }
    .ant-radio-group{
      .ant-radio-button-wrapper{border-radius:0 !important;}
      &-large{
        .ant-radio-button-wrapper {font-size: 14px;}
      }
    }
  }

  &-item-required {
    position     : relative;
    padding-right: 14px;

    &:before {
      position: absolute;
      right   : 0px;
      top     : 0px;
    }
  }

  .ant-input ,[class*="ant-input-password-"]{
    border-radius: 0px;
  }

  .ant-picker {
    border-radius: 0px;
    display      : flex;

    &-large {
      padding: 10px 11px;
    }

    input {
      font-family: @font-md;
      font-size  : 14px;
      ::-webkit-input-placeholder,:-moz-placeholder,::-moz-placeholder,:-ms-input-placeholder {
        color: transparent;
      }
          
    }
  }
}
.ant-select-multiple{
  &.ant-select-lg{
    .ant-select-selection-item {
      height: 26px;line-height: 26px;font-size: 12px;
    }
  }
}

.medicalLicense{
  .ant-space-item{width: 100%;}
  .ant-input{
    &.ant-input-lg{height: 40px;}
  }
}
.phoneField{
  .select-before {
    min-width: 110px;
      .ant-select-selector {
        padding-left: 5px; padding-right: 5px;
    }
  }
  .ant-input{padding-right:6px;}
  .ant-input-group-addon{
    padding: 0;
    .select-before{
      border: 0; padding: 0 10px; height: 38px; outline: none;
      border-radius: 0;
    }
  }
}
.ant-form-item-control-input{
  min-height: inherit;
}
