.ant-modal-root {
    .ant-modal {
        &-content {
            border-radius: 2px;
        }

        &-confirm {
            .ant-modal-body {
                padding: 30px 25px 24px;
            }
        }

        &-confirm-body {
            .ant-modal-confirm-title {
                font-family: @font-md;
            }
        }

        &-title {
            font-family: @font-sm;
        }
        &.bookAppointment{
            .ant-form-item-control-input-content {
                align-items: center;
                justify-content: center;
                display: flex;
            }
        }
        @media screen and (max-width: @screen-md-max) {
            .ant-modal-header{padding: 15px 20px;}
            .ant-modal-body{
                padding: 20px;
            }
        }
        @media screen and (max-width: @screen-sm-max) {
            .ant-modal-header{padding: 15px;}
            .ant-modal-body{
                padding:20px 15px;
            }
        }
        &.addreason-modal{
            .orOption{
                text-align: center; border-top: 1px solid #e8e8e8; margin-top: 30px;
                span{ background: #fff; position: relative;
                    top: -15px; height: 30px;
                    width: 30px; border: 1px solid #e8e8e8; border-radius: 50%; display: inline-block; line-height: 29px;font-size: 12px;}
            }
        }
    }
    @import "modal-components/schedule-modal";
    @import "modal-components/instant-booking";
    @import "modal-components/visit-reason";
    @import "modal-components/discharge-info";
    @import "modal-components/appointment-popup-modal";
    @import "modal-components/prescription-modal";
    
}
@import "modal-components/confirm-modal";