.waitingRoom{ margin-bottom: 30px;
    &__left{
        background:@white-color; box-shadow:0 6px 20px rgba(@black-color,0.06); padding:20px;
        .ant-collapse{border:0; border-radius:0; background:transparent;
            .ant-collapse-item{border-bottom: 0;
                &:not(:last-child){margin-bottom: 5px;}
                .ant-collapse-header{background:#F2F7F7; color:@black-color; font-weight:600; font-size:13px; padding: 15px; border-radius:0 !important;
                    .ant-collapse-arrow{right: 15px; left: auto; top: 6px;}
                }
                &.ant-collapse-item-active{
                    .ant-collapse-header{background:#E5E5E5;}
                }
            }
            .ant-collapse-content{border-top:0;
                .ant-collapse-content-box{padding: 0;}
            }
        }
        ul{
            background:#F2F7F7;
            li{
                a{padding:10px 15px; display: flex; align-items: center; color:#000;
                    img{height: 35px; margin-right: 10px;}
                    
                    &.active{background:#f2f2f2;}
                }
                &:not(:last-child){
                    border-bottom:1px solid #E5E5E5;
                }
            }
        }
    }
    &__right{
        .documentBox{
            background:@white-color; box-shadow:0 6px 20px rgba(@black-color,0.06); padding:50px;
            min-height: 350px; display: flex; align-items: center; justify-content:center; flex-direction:column;
            img{max-height:120px;}
            h4{margin: 25px 0;}
            &.videos{ font-size:0;
                video{object-fit:cover; height: 350px;}
            }
            audio{max-width:100%;}
            iframe{height: 350px; width: 100%;}
        }
    }
    @media  (max-width:1439px) {
        &__right{
            .documentBox{
                padding:35px;
                img{max-height:110px;}
                h4{margin: 20px 0;}
            }
        } 
    }
    @media screen and (max-width: @screen-lg-max) {
        &__left{
            padding: 15px;
        }
        &__right{
            .documentBox{
                padding:25px; min-height: 300px;
                img{max-height:90px;}
                &.videos{
                    video{height: 300px;}
                }
                iframe{height: 300px;}
            }
        } 
    }
    @media screen and (max-width: @screen-md-max) {
        &__left{
            ul{
                li{
                    a{font-size:13px; padding: 10px;}
                }
            }
        }
    }
    @media screen and (max-width: @screen-sm-max) {margin-bottom:20px;
        &__right{
            margin-top: 20px;
            .documentBox{
                padding:20px 15px; min-height:inherit;
                img{max-height:80px;}
                h4{margin: 15px 0;}
                &.videos{
                    video{height: 250px;}
                }
                iframe{height: 250px;}
            }
        } 
    }
    @media screen and (max-width: @screen-xs-max) {
        &__left{
            .ant-collapse{
                .ant-collapse-item{
                    .ant-collapse-header{padding:12px 15px;
                        .ant-collapse-arrow{margin-left: 10px;}
                    }
                }
            }
            ul{
                li{
                    a{
                        img{height:30px;}
                    }
                }
            }
        }
        &__right{
            .documentBox{
                img{max-height:60px;}
                h4{font-size:14px;}
                &.videos{
                    video{height: 200px;}
                }
                iframe{height: 200px;}
            }
        } 
    }
}