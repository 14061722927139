.ant-picker-dropdown {
    .ant-picker-header {
        &-view {
            font-family: @font-sm;
        }
    }

    .ant-picker-body {
        font-family: @font-md;

        .ant-picker-cell {
            &-inner {
                border-radius: 0px;
            }

            &-today {
                .ant-picker-cell-inner {
                    background-color: transparent;

                    &:before {
                        border: 0px;
                    }
                }
            }

            &-selected {
                .ant-picker-cell-inner {
                    background-color: @secondary-color;
                }
            }
        }

    }
    .ant-picker-time-panel-column{
        &:after{display: none;}
    }
}