/*Loader Styles*/
.ant-spin {
  margin-right: 16px;
  margin-bottom: 10px;
}

.loader-container {
  text-align: center;
  background-color: @grey-3;
  .border-radius(@border-radius-sm);
  padding: @size-30 @size-50;
  margin-right: 0;
  display: block;
}


.loader-view{
  position:fixed;
  height:100%;
  width:100%;
  top:0px;
  background-color:rgba(255,255,255,0.5);  
  z-index:10;
  left:0px;
}
