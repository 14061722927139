.schedulesPage{ margin-bottom: 30px;
	.clinicList{ background:#fff; box-shadow:0 0 20px rgb(38 38 38 / 7%); margin-bottom: 1px;
        ul{padding: 0; margin: 0; list-style:none; display: flex;overflow: auto;
            a{padding: 10px 15px; border-right: 1px solid #E2E2E2; max-width: 250px; display: block; text-align:center;
                img{width: 36px; height: 36px; min-width: 36px; margin-bottom: 5px; border-radius:50%;}
                h6{margin-bottom: 5px;font-size: 14px;font-weight: 600;}
                p{ color: #535353;font-size: 12px;}
                &.active{background:#038fde;
                    h6,p{color:#fff;}
                }
            }
        }
    }
    &__wrap{padding: 30px; box-shadow:0 0 20px rgba(@header-text-color,0.07); background:@white-color;
		.ant-col{
			&.ant-form-item-control{
				width: 100%;
			}
		}
	}
    .boxHeading{
		&__right{
			a{height: 40px;width: 40px;  border:1px solid rgba(#BFBFBF,0.35); color: @heading-color; font-size:17px; border-radius:5px;
                display: flex; align-items:center; justify-content:center; transition:all 0.3s ease;
				+ a{margin-left: 10px;}
				&:hover{background:@primary-color; color:@white-color; border-color:@primary-color;}
			}
		}
	}
    .dateSlide{
		h5{margin: 0 25px;}
		.arrow{font-size:20px; vertical-align: middle; position: relative; top: 2px; color:@primary-color; cursor: pointer;}
	}
	.dateSlot{
		position: relative; border:1px solid #F2F2F2; border-radius: 10px;padding: 0 45px; overflow: hidden; width: 70%; margin-bottom: 30px;
        ul{list-style:none; padding: 0;}
		li{
			flex: 1 1 14.285%; text-align:center; padding: 18px 10px; border-radius:5px; cursor: pointer;
			.day{font-size:16px; font-weight:600; margin-bottom: 4px; display: block;}
			.date{font-size:15px; color:#808080; margin: 0;}
			&.today-active{background-color:@secondary-color; color:@white-color;
				.date{color:@white-color;}
			}
			&.current-Date{
				background-color:@layout-header-background-dark; color:@white-color;
				.date{color:@white-color;}
			}
		}
		&__icon{
			position: absolute;background:#F7F8F9;color: @primary-color; width: 42px;left: 0;top: 0;height: 100%;
			text-align: center;display: flex;flex-direction: column;justify-content: center;font-size: 22px; cursor:pointer; transition:all 0.3s ease;
			&--right{right: 0;left: auto;}
			&:hover{background:@primary-color; color:@white-color;}
		}
	}
	.timeslotBox{.ant-radio-group{width: 100%;}}
    .timeslot{
		margin: 0 -15px 20px; flex-wrap:wrap; max-height: 300px; overflow: auto;
		
		&__list{
			padding: 0 15px; flex: 1 1 16.666%; max-width: 16.666%;
			&:not(:last-child){
				margin-bottom: 15px;
			}
            .ant-radio-wrapper{
                border: 1px solid #737374; border-radius: 4px; padding:12px 10px; width: 100%; display: flex; justify-content:center;
                color:#737374; font-size: 14px; cursor:pointer; margin-bottom: 0; text-align: center;
                &.ant-radio-wrapper-checked{
                    color: @white-color; background:@secondary-color; border-color: @secondary-color;
                }
                .ant-radio{display: none;
                    + * {padding: 0;}
                }
            }
		}
	}
	@media(max-width:1680px){
		.timeslot{
			margin: 0 -10px 20px;
			&__list{
				flex:1 1 20%; max-width: 20%; padding: 0 10px;
				.ant-radio-wrapper{
				padding:10px 8px; 
				}
			}
		}
	}
	@media(max-width:1439px){
		&__wrap{padding: 25px;}
		.dateSlot{
			padding: 0 33px; width: 85%; margin-bottom: 25px;
			li{
				.day{font-size:15px;}
				.date{font-size:14px;}
			}
			&__icon{
				width:33px;font-size: 20px; 
			}
		}
		.timeslot{
			margin: 0 -7px 20px;
			&__list{
				padding: 0 7px;
				.ant-radio-wrapper{padding:10px 6px;}
			}
		}
	}
	@media screen and (max-width: @screen-md-max) {
		&__wrap{padding: 20px;}
		.dateSlot{
			width: 100%; margin-bottom: 20px;
		}
		.timeslot{
			&__list{
				flex:1 1 25%; max-width:25%;
				.ant-radio-wrapper{padding: 8px 5px;}
			}
		}
	}
	@media screen and (max-width: @screen-sm-max) {
		margin-bottom: 20px;
		&__wrap{padding: 15px;}
		.boxHeading{
			&__right{
				a{height: 35px;width: 35px; font-size:15px;
					+ a{margin-left: 6px;}
				}
			}
		}
		.dateSlide{
			h5{margin: 0 15px;}
			.arrow{font-size:18px;}
		}
		.dateSlot{margin-bottom: 15px; padding: 0 25px;
			&__icon{width:25px;font-size: 17px; }
			li{
				padding:15px 8px;
				.day{font-size: 13px; margin-bottom: 6px;}
			}
		}
		.timeslot{
			margin: 0 -5px 20px;
			&__list{
				flex:1 1 33.33%; max-width:33.33%; padding: 0 5px;
				.ant-radio-wrapper{font-size: 13px;}
			}
		}
		.clinicList{
            ul{
                a{padding: 5px 10px; display: flex; align-items: center; text-align:left;
                    img{width: 30px; height: 30px; min-width: 30px; margin-bottom: 0px; margin-right: 8px;}
                    h6{font-size:13px; margin-bottom: 3px;}
                }
            }
        }
	}
	@media screen and (max-width: @screen-xs-max) {
		.boxHeading{ justify-content: space-between;
			h4{font-size: 14px; width:100%;} 
			&__right{
				a{height: 30px;width: 30px; font-size:12px;
					+ a{margin-left: 4px;}
				}
			}
		}
		.dateSlot{
			ul{flex-wrap: wrap;}
			li{
				flex: 1 1 25%; padding:10px 5px;
				.day {font-size:12px;}
				.date{font-size:13px;}
			}
		}
		.dateSlide{
			h5{margin: 0 4px;}
		}
		.timeslot{
			max-height:250px; margin-bottom: 15px;
			&__list{
				flex:1 1 50%; max-width:50%;
			}
		}
	}
	@media (max-width:374px) {
		.timeslot{
			&__list{
				flex:1 1 100%; max-width:100%;
			}
		}
	}
	
}