.instantBooking{
    .communicationMode{
        .ant-radio-group{display: flex; justify-content:center;
            span{padding: 0; margin: 0; line-height:0;
                &.ant-radio{display: none;}
            }
        }
        &__list{
            .ant-radio-wrapper{
                height: 55px; width: 55px; background:#E2E9F1; border-radius:50%; display: flex; align-items:center; justify-content:center;
                color:@black-color; font-size:22px;
                .ico-video1{font-size:18px;}
                &-checked{background:@secondary-color; color:@white-color;}
            }
            &:not(:last-child){margin-right: 30px;}
            label{margin: 0;}
        }
        @media (max-width:1439px){
            &__list{
                .ant-radio-wrapper{
                    height: 50px; width: 50px; font-size:20px;
                    .ico-video1{font-size:16px;}
                }
                &:not(:last-child){margin-right: 20px;}
            }
        }
        @media screen and (max-width: @screen-xs-max) {
            &__list{
                .ant-radio-wrapper{
                    height: 45px; width: 45px; font-size:18px;
                    .ico-video1{font-size:15px;}
                }
            }
        }
    }
}