.dashboard  {
    &-counter {        
        .ant-card {
            margin-bottom:20px;
            &-head {
                border-bottom: 0px;
                &-title{
                    font-family: @font-md;
                }
            }
            &-body {
                padding    : 20px;
                padding-top: 0px;
                .number {
                    font-size: 25px;
                    font-family: @font-bd;
                }
                .count{
                    span{
                        font-size:13px;
                        span{
                            color:@secondary-color; 
                        }
                    }
                    &-appointment{
                        label{display:block; margin-bottom:5px;}
                        span{font-weight:600; font-size:14px;}
                    }
                }
                .countbox {
                    display: flex;
                    align-items: center;
                    &-icon{
                        min-width:50px;
                        margin-right:15px;
                        span{
                            display:flex;
                            width:45px;
                            height:45px;
                            align-items: center;
                            justify-content: center;
                            font-size: 26px;
                            border-radius:100%;
                            background-color:rgba(#fa8c15,0.1);
                            color:@secondary-color;
                        }
                    }
                }
            }
        }
    }
    .sub-title{
        font-size: 16px;
        font-family: @font-sm;
        margin-bottom: 15px;
        &--height{
            min-height: 36px;
            line-height: 36px;
        }
    }
    .ant-tabs{
        background-color: @white-color;
        margin-bottom: 30px;
        border: 1px solid #e8e8e8;
        border-radius: 6px;
        .ant-tabs-nav{
            margin-bottom: 0;
            padding: 0 15px;
        }
        .ant-tabs-tab{
            justify-content: center;
            padding: 15px 0;
            &-active{
                .ant-tabs-tab-btn{
                    color: @layout-header-background-dark;
                }
            }
            &:hover{
                color: @layout-header-background-dark;
            }
        }
        .ant-tabs-ink-bar{
            background: @layout-header-background-dark;
        }
    }
    .ant-table-wrapper{
        margin-bottom: 0;
        .serialNum {
            width: 55px;
        }
        .action{
            width: 65px;
            text-align: center;
        }
        table{
            tr{
                th, td{
                    white-space: nowrap;
                    &:first-child{
                        padding-left: 15px;
                    }
                }
                &:last-child{
                    td{border:0;}
                }
            }
        }
    }
    .graph{
        &-wrap{
            background-color: @white-color;
            border: 1px solid #e8e8e8;
            border-radius: 6px;
            margin-bottom: 30px;
        }
    }
    .headRow{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        &-right{
            margin-left: auto;
            margin-bottom: 15px;
            width: 120px;
            .ant-form-item{
                margin-bottom: 0;
            }
            &-search{
                width: 230px;
                .ant-input, .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button{
                    border-radius: 0;
                }
            }
        }
    }
    &-patient{
        .appointments{
            box-shadow: none;
        }
        .appointmentBox{
            &__top{
                span{
                    display: inline-block;
                    margin-bottom: 10px;
                }
            }
            .consultation{
                margin-top: 0;
            }
        }
    }
    @media(max-width: 1599px) {
        &-counter {        
            .ant-card {
                &-body {
                    .number {
                        font-size: 20px;
                    }
                    .countbox{
                        &-icon{
                            min-width: 40px;
                            span{
                                width: 40px;
                                height: 40px;
                                font-size: 20px;
                            }
                        }
                    }
                }
                &-head{
                    padding: 0 20px;
                    &-title{
                        font-size: 15px;
                    }
                }
            }
        }
    }
    @media(max-width: 991.98px) {
        .graph{
            &-wrap{
                margin-bottom: 20px;
            }
        }
        .ant-tabs{
            margin-bottom: 20px;
        }
    }
    @media(max-width: 767.98px) {
        &-counter {        
            .ant-card {
                &-head{
                    padding: 0 15px;
                }
                &-body {
                    padding: 15px;
                    padding-top: 0;
                }
            }
        }
    }
    @media(max-width: 575.98px) {
        .graph{
            &-wrap{
                overflow-x: auto;
                overflow-y: hidden;
            }
            &-chart{
                width: 537px;
            }
        }
        .sub-title{
            font-size: 15px;
        }
    }
}