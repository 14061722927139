/*Pagination Styles*/
.ant-pagination {
  padding: 0 20px;
  & > li {
    margin-bottom: 10px;
  }

  &.mini {
    &:not(:last-child) {
      margin-bottom: 10px;
    }

    & li {
      margin-bottom: 5px !important;
    }
  }
  &-item{margin-right: 0; border-radius:0;}
  &-prev,
  &-next,
  &-jump-prev,
  &-jump-next { margin-right: 0;
    line-height: @pagination-item-size - 2px;
  }
  &-prev{
    .ant-pagination-item-link{border-radius:6px 0 0 6px;}
  }
  &-next{
    .ant-pagination-item-link{border-radius:0 6px 6px 0;}
  }
}
