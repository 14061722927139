/* Header Style */
.ant-layout-header {
  .box-shadow(@ct-card-shadow-lg);
  .flex-display(flex, row, wrap);
  .align-items(center);
  .justify-content(space-between);
  line-height: 1;
  padding    : @layout-header-padding;
  height     : @layout-header-height;
  position   : relative;
  z-index    : 10;
  color      : @header-text-color;

  @media screen and (max-width: @screen-md-max) {
    padding: @layout-header-padding-res;
  }

  @media screen and (max-width: @screen-xs-max) {
    height: @layout-header-height-res;
  }

  @media(max-width:575.98px) {
    .logo {
      img {
        width: 28px;
      }
    }
  }
}

.linebar {
  font-size: 20px;
}

.header-notifications {
  list-style  : none;
  margin      : 0;
  padding-left: 0;
  .flex-display(flex, row, wrap);
  .align-items(center);

  &>li {
    font-size: 18px;

    &:not(:last-child) {
      margin-right: 42px;

      @media (max-width: 1599.98px) {
        margin-right: 25px;
      }

      @media screen and (max-width: @screen-md-max) {
        margin-right: 16px;
      }
    }

    &.notify {
      margin-right: 22px; position: relative;

      .inside-header-horizontal & {
        margin-right: 20px;
      }
      &-message{
        a{color:@black-color;
          &:hover{color:@secondary-color;}
        }
      }
      .notificationCount{
        position: absolute; right: -6px; min-height: 12px; min-width: 12px; padding: 2px; border-radius:50%; font-size: 8px; color:@white-color;
        top: -6px; background:@layout-header-background-dark; display: flex; align-items:center; justify-content:center;
      }
      @media screen and (max-width: @screen-md-max) {
        margin-right: 16px;
      }
    }

    &.language {
      font-size: 14px;

      .language-name {
        font-family: @font-md;
      }

      @media screen and (max-width: @screen-xs-max) {

        & .language-name,
        & .icon {
          display: none;
        }

        & .flag.flag-24 {
          width     : @size-20;
          height    : @size-20;
          margin-top: -4px;
        }
      }
    }
    &.user-nav {
      .avatar-name {
        font-family: @font-md;
        font-size  : 15px;
        cursor     : pointer;
      }
    }
    @media screen and (max-width: @screen-xs-max) {
      &.notify {
        margin-top: 2px;
      }

      &.language {
        margin-bottom: 2px;
      }
      &.user-nav {
        .ant-avatar{height: 35px !important; width: 35px !important;}
        .avatar-name {
          font-size  : 0;
        }
      }
    }
  }
}
.notification-popover{
  p{
    font-family:@font-sm;
  }
  .popover-bottom{
    margin-top:20px;
  }
  
}
@media screen and (max-width: @screen-xs-max) {
  .ant-popover-arrow{
    display: none;
  }
}
.popover-scroll {
  height: 280px !important;
  width : 300px !important;
  margin: 0 -16px;

  @media screen and (max-width: @screen-xs-max) {
    height: 280px !important;
    width :300px !important;
  }
}

.popover-lang-scroll {
  // height: 70px !important;
  width : 160px !important;
  margin:-5px -22px;
  .language-text{
    font-family: @font-md;
  }
}

.ant-popover-placement-bottomRight {
  &>.ant-popover-content {
    &>.ant-popover-arrow {
      right: 10px;
    }
  }
}

.popover-header {
  .flex-display(flex, row, wrap);
  .align-items(center);
  .justify-content(space-between);
  border-bottom: @border-style-base @border-width-base @border-color;
  margin       : 0 -16px;
  padding      : 0 16px 12px;
}

.sub-popover {
  list-style  : none;
  margin      : 0 16px;
  padding-left: 0;

  & li {
    padding: 20px 0 14px;
    .popover-lang-scroll & {
      padding: 5px 0;
      display: flex;
      align-items: center;
    }

    &:not(:last-child) {
      border-bottom: @border-style-base @border-width-base @border-color;
    }
  }
}

.user-popover {
  margin    : -12px -16px;
  padding   : 7px 0;
  list-style: none;
  width:150px;
  li {  

    a {
      font-family: @font-md;
      display    : block;
      padding    : 8px 15px;
      color      : #333;

    }

    &:hover,
    &:focus {
      background-color: #fff2ec;

      a {
        &:hover {
          color: @secondary-color  !important;
        }
      }
    }

   
  }
}