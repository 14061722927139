/*Tables Styles*/

.ant-table-small {
  & .ant-table-thead>tr>th {
    background-color: transparent;
  }

  >.ant-table-content {

    >.ant-table-header>table,
    >.ant-table-body>table,
    >.ant-table-scroll>.ant-table-header>table,
    >.ant-table-scroll>.ant-table-body>table,
    >.ant-table-fixed-left>.ant-table-header>table,
    >.ant-table-fixed-right>.ant-table-header>table,
    >.ant-table-fixed-left>.ant-table-body-outer>.ant-table-body-inner>table,
    >.ant-table-fixed-right>.ant-table-body-outer>.ant-table-body-inner>table {
      padding: 0;
    }

    >.ant-table-body {
      margin: 0;
    }
  }
}

.ant-table {
  &-wrapper {
    border       : 1px solid #F3F3F3;
    margin-bottom: 30px;
    border-radius: 0px;
    background   : #fff;
  }

  &-thead>tr>th,
  &-tbody>tr>td {
    font-size: 14px;
border-bottom-color: #F3F3F3 !important;
    .ant-dropdown-trigger {
      svg {
        line-height: normal;
        font-size  : 24px;
        color      : #526484;
      }
    }

    &.mw-300 {
      max-width: 300px;
    }
    .summaryImage{ display: flex; align-items: center;
      img{width: 35px; margin-right: 10px;}
      p{max-width:calc(100% - 45px); margin: 0;}
    }
    .userImage{display: flex; align-items: center;
      img{height: 40px; width: 40px; border-radius:50%; margin-right: 10px;}
      p{max-width:calc(100% - 50px); margin: 0;}
    }
    .user-card{ display: flex; align-items: center;
      .user-avatar{height: 40px; width: 40px; border-radius:5px; overflow: hidden; display: flex; align-items:center; justify-content:center;
        background:@primary-color;
        img{max-width: 100%;}
      }
      .user-info{margin-left: 15px; color:@text-color;
        .tb-lead{font-weight:600; 
          + span{font-size:12px; margin-top: 2px;}
        }
      }
    }
    .dateTime{
      h5{margin-bottom: 0; font-weight:600;}
      span{font-size:12px; margin-top: 5px;}
    }
    .ant-empty{
      color:#f5222d;
      .ant-empty-image{
        .ant-empty-img-simple-g{stroke:#f5222d;}
      }
    }
    .nowrap-space,.dark-blue-color{ white-space:nowrap;}
  }

  &-tbody>tr>td {
    font-family: @font-md;
  }

  &-thead tr th {
    background : transparent;
    font-family: @font-sm;
    color      : @layout-header-background-dark;
    white-space: nowrap;

    &:hover {
      background-color: transparent !important;
    }

    &:after,
    &:before {
      display: none;
    }

  }

  &-row {
    &-selected {
      td {
        background-color: @secondary-light-color !important; 
      }
    }
  }

  @media(max-width:767px) {
    &-wrapper {margin-bottom: 20px;}
    &-thead>tr>th,
    &-tbody>tr>td {
      font-size: 13px;
    }
  }
}

.table-responsive .ant-table {
  min-height: .01%;
  overflow-x: auto;
}

@media screen and (max-width: @screen-sm-max) {
  .table-responsive .ant-table {
    width             : 100%;
    overflow-y        : hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
   // border            : @border-style-base @border-width-base @border-color;

    .ant-card-bordered.card-full & {
      border       : 0 none;
      border-bottom: @border-style-base @border-width-base @border-color;
    }

    & .ant-table-tbody>tr:last-child>td {
      border-bottom: 0 none;
    }

    &.ant-table-bordered {

      & .ant-table-header>table,
      & .ant-table-body>table,
      & .ant-table-fixed-left table,
      & .ant-table-fixed-right table {
        border-top : 0 none;
        border-left: 0 none;
      }

      & .ant-table-title {
        border: 0 none;
      }

      & .ant-table-footer {
        border    : 0 none;
        border-top: @border-style-base @border-width-base @border-color;
      }

      & .ant-table-thead>tr>th:last-child,
      & .ant-table-tbody>tr>td:last-child {
        border-right: 0 none;
      }
    }
  }

  .table-responsive .ant-table-body>table>thead>tr>th,
  .table-responsive .ant-table-body>table>tbody>tr>th,
  .table-responsive .ant-table-body>table>tfoot>tr>th,
  .table-responsive .ant-table-body>table>thead>tr>td,
  .table-responsive .ant-table-body>table>tbody>tr>td,
  .table-responsive .ant-table-body>table>tfoot>tr>td {
    white-space: nowrap;
  }
}

.table {
  width           : 100%;
  max-width       : 100%;
  margin-bottom   : 1rem;
  background-color: transparent;

  & th,
  & td {
    padding       : 0.75rem;
    vertical-align: top;
    border-top    : @border-style-base @border-width-base @border-color;
  }

  & thead th {
    vertical-align: bottom;
    border-bottom : 2px @border-style-base @border-color;
  }

  & tbody+tbody {
    border-top: 2px @border-style-base @border-color;
  }
}

.table-bordered {
  border: @border-width-base @border-style-base @border-color;

  & th,
  & td {
    border: @border-width-base @border-style-base @border-color;
  }

  & thead th,
  & thead td {
    border-bottom-width: 2px;
  }
}

.table-no-bordered {
  border: 0 none;

  & th,
  & td {
    border: 0 none;
  }

  & thead th,
  & thead td {
    border-bottom-width: 2px;
  }

  & .ant-table {
    border: 0 none;
    .border-radius(0);
  }

  & .ant-table-thead>tr>th {
    font-weight   : @font-weight-light;
    text-transform: uppercase;
    font-size     : @font-size-sm - 1px;
  }

  & .ant-table-tbody>tr>td {
    border-bottom: 0 none;
  }
}

.table-thead-uppercase {
  & thead th {
    text-transform: uppercase;
  }
}

.table-padding-lg {

  & thead th,
  & tbody td {
    padding: 20px 30px !important;

    @media screen and (max-width: @screen-md-max) {
      padding: 15px 20px !important;
    }

    @media screen and (max-width: @screen-sm-max) {
      padding: 10px !important;
    }
  }

  & .ant-table-pagination {
    float     : none;
    text-align: center;
    margin    : 22px 0;

    @media screen and (max-width: @screen-md-max) {
      margin: 16px 0;
    }
  }
}

.ant-dropdown {
  .tableDropdown {
    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
      padding    : 5px 15px;
      font-size  : 13px;
      font-family: @font-md;

      span {
        margin-right: 7px;
      }

      &:hover {
        background-color: @secondary-light-color;
      }
    }
  }
}