.modal{
    position: fixed; left:0; top:0; z-index:1001; height: 100%; width: 100%;
    &-backdrop{
        &.show{
            position: fixed; top: 0; right: 0; bottom: 0; left: 0;
            z-index: 1000; height: 100%; background-color: rgba(0, 0, 0, 0.45);
        }
    }
    .modal-dialog{position: relative; top: 50%;left: 50%; transform:translate(-50%,-50%);
        .modal-content{
            background:@white-color; border-radius:2px; box-shadow:0 3px 6px -4px rgba(@black-color,0.12), 0 6px 16px 0 rgba(@black-color,0.08), 0 9px 28px 8px rgba(@black-color,0.05);
            .modal-body{padding:30px 25px; text-align: center;
                p{font-size:16px; color:@black-color;}
                .btn-row{margin-top: 30px;}
            }
        }
    }
    &-sm{max-width:450px;}
}