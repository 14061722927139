.ant-btn{
  border-radius:0px;
  font-family: @font-md;
  &-info{
    background-color:@secondary-color;
    color:#fff;
    border-color:@secondary-color;
    &:hover,&:focus{
      background-color:lighten(@secondary-color,3%);
      color:#fff;
      border-color:lighten(@secondary-color,3%);
    }
  }
  &.ant-btn-icon-only{border-radius:0;}
  @media screen and (max-width: @screen-xs-max) {
    &-lg{padding-left: 10px; padding-right: 10px;}
  }
}