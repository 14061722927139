.dailyAppointment{
    &__calendar{
        .ant-picker-calendar{
            border-radius:0; box-shadow:0 0 20px rgba(#262626,0.07);
            .ant-picker-calendar-header{padding: 15px;
                .ant-radio-group{display: none;}
            }
            .ant-picker-panel{padding: 15px;}
            .ant-picker-content{
                thead{
                    th{padding: 5px;}
                }
                .ant-picker-cell{
                    .ant-picker-cell-inner{
                        height: 45px; width: 45px; border-radius:50%; display: flex; align-items:center; justify-content:center; margin: 0 auto;
                        &:before{display: none;}
                    }
                    &.ant-picker-cell-today{
                        .ant-picker-cell-inner{
                            background:@secondary-color !important; color:@white-color !important;
                        }   
                    }
                    &.ant-picker-cell-selected{
                        .ant-picker-cell-inner{
                            background:@layout-header-background-dark !important; color:@white-color !important;
                        }   
                    }
                }
            }
        }
    }
    .doctorList{
        background:@white-color;box-shadow:0 0 20px rgba(#262626,0.07); margin-bottom: 30px;
        
        .item{text-align: center; padding: 15px 0;  border-bottom: 2px solid transparent; cursor: pointer;
            &.current{border-bottom-color: @secondary-color;}
            h4{margin: 0; font-size: 14px; font-weight:600;}
        }
        &__img{
            height: 100px; width: 100px; border-radius:50%; position: relative; margin: 0 auto 15px;
            img{height:100%; width:100%; border-radius:50%;}
            span{position: absolute; height: 16px; width: 16px; border-radius: 50%; background: #7a7a7a;
                border:2px solid @white-color; right: 6px; bottom: 7px;
                &.online{background:@primary-color;}
            }
        }
        .owl-carousel{padding: 0 35px;}
        .owl-nav{
            button{
                position: absolute;left: 0;top: 0;width: 35px; height: 100%; background: @secondary-light-color !important;
                color:@secondary-color !important; margin: 0; font-size:30px !important; border-radius:0;
                &.owl-next{right: 0; left: auto;}
                &:hover{background:@secondary-color !important; color:@white-color !important;}
                &.disabled{background:#fff2ec !important;color:#F77E49 !important;}
            }
            &.disabled{display: block;}
        }
    }
    .bookingSlot{ margin-bottom: 30px;
        ul{display: flex; flex-wrap: wrap; margin: 0 -7px; list-style: none; padding: 0;
            li{flex:1 1 20%; max-width:20%; padding: 0 7px;}
            .slotList{background:@white-color; padding:20px 15px; box-shadow:0 0px 6px rgba(@black-color,0.1); font-weight:600; height: 100%;
                span{display: block; margin-top: 15px;}
                &--booked{
                    span{color:@layout-header-background-dark;}
                }
                &--pending{
                    span{color:@secondary-color;}
                }
                &--call{
                    span{color:#e91e63;}
                }
                &--completed{
                    span{color:@primary-color;}
                }
            }
        }
    }
    .slotColor{
        ul{display: flex; flex-wrap: wrap; margin: 0 -7px; list-style: none; padding: 0;
            li{flex:1 1 auto; padding: 0 7px; font-weight:600;}
        }
        &__box{position: relative;
            padding-left: 28px;
            &:after{
                content:""; position: absolute; height: 18px;top: 0; width: 18px; border:1px solid #ddd; background:@white-color; left: 0;
            }
            &--booked{
                &:after{background:@layout-header-background-dark;}
            }
            &--pending{
                &:after{background:@secondary-color;}
            }
            &--call{
                &:after{background:#e91e63;}
            }
            &--completed{
                &:after{background:@primary-color;}
            }
        }
        @media (max-width:1599px){
            ul{
                li{
                    &:not(:last-child){margin-bottom: 10px;}
                }
            }
            &__box{padding-left: 25px; font-size:13px;
                &:after{height: 16px;width: 16px;}
            }
        }
    }
    .boxHeading{
        .ant-form-item{
            min-width:150px;
        }
    }
    .durationWrapper{margin: 30px 0;
        // .timeList{max-height: 350px; overflow: auto;}
        .durationList{margin-bottom: 15px;}
        h6{margin-bottom: 15px; font-weight:600; font-size:14px;}
        ul{
            list-style: none;margin: 0; padding: 0; background:@white-color; box-shadow:0 0 20px rgba(#262626,0.07);
            display: flex; flex-wrap: wrap; max-height:310px; overflow:auto;
            border-top:1px solid #e9e9e9; border-right:1px solid #e9e9e9;
            li{
                flex:1 1 8.33%; max-width:8.33%; padding: 10px;border-left:1px solid #e9e9e9; border-bottom: 1px solid #e9e9e9; text-align: center;
                &.accept{background:@layout-header-background-dark; color:@white-color;}
                &.pending{background:@secondary-color; color:@white-color;}
                &.entered{background:#e91e63; color:@white-color;}
                &.completed{background:@primary-color; color:@white-color;}
            }
        }
    }
    @media (max-width:1599px){
        .doctorList{
            .item{
                h4{font-size: 13px;}
            }
            &__img{
                height:80px; width:80px; 
            }
        }
        .bookingSlot{
            margin-bottom: 25px;
            ul{
                .slotList{ padding:15px 10px; font-size:13px;
                    span{display: block; margin-top: 10px;}
                }
            }
        }
        .durationWrapper{
            ul{
                li{flex:1 1 10%; max-width:10%;}
            }
        }
    }
    @media (max-width:1366px){
        &__calendar{
            .ant-picker-calendar{
                .ant-picker-calendar-header{padding:15px 10px;}
                .ant-picker-panel{padding: 15px 10px;}
                .ant-picker-content{
                    .ant-picker-cell{
                        .ant-picker-cell-inner{height:40px; width: 40px;}
                    }
                }
            }
        }
    }
    @media screen and (max-width: @screen-lg-max) {
        &__calendar{
            .ant-picker-calendar{
                .ant-picker-calendar-header{padding: 10px;}
                .ant-picker-panel{padding: 15px 10px;}
                .ant-picker-content{
                    .ant-picker-cell{
                        .ant-picker-cell-inner{height:37px; width: 37px;}
                    }
                }
            }
        }
    }
    @media screen and (max-width: @screen-md-max) {
        &__calendar{ margin-bottom: 20px;
            .ant-picker-calendar{
                .ant-picker-calendar-header{padding: 10px 5px;
                    .ant-radio-button-wrapper{font-size:12px; padding: 0 3px;}
                }
                .ant-picker-panel{padding: 10px 5px;}
                .ant-picker-content{
                    .ant-picker-cell{
                        .ant-picker-cell-inner{height:30px; width: 30px; font-size:13px;}
                    }
                }
            }
        }
        .doctorList{ margin-bottom: 20px;
            .item{padding: 10px 0;}
            &__img{
                height:70px; width:70px;
                span{height: 13px; width: 13px;}
            }
            .owl-carousel{padding: 0 30px;}
            .owl-nav{
                button{width: 30px;}
            }
        }
        .bookingSlot{ margin-bottom: 10px;
            ul{ flex-wrap: wrap;
                li{ flex:0 0 33.33%; max-width:33.33%; 
                    .slotList{margin-bottom: 10px; height: calc(100% - 15px);}
                }
            }
        }
        .durationWrapper{ margin: 20px 0;
            ul{max-height:300px;
                li{flex:1 1 14.285%; max-width:14.285%;}
            }
        }
    }
    @media screen and (max-width: @screen-sm-max) {
        .doctorList{
            &__img{
                height:55px; width:55px;
                span{height: 10px; width: 10px;}
            }
        }
        .durationWrapper{ margin: 15px 0 10px;
            ul{
                li{flex:1 1 20%; max-width:20%;}
            }
        }
    }
    @media screen and (max-width: @screen-xs-max) {
        .bookingSlot{
            ul{ 
                li{ flex:0 0 50%; max-width:50%; }
            }
        }
        .durationWrapper{
            ul{
                li{flex:1 1 25%; max-width:25%;}
            }
        }
    }
    @media (max-width:479px) {
        .durationWrapper{
            ul{
                li{flex:1 1 33.33%; max-width:33.33%;}
            }
        } 
    }
}