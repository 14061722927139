.authLayout {
    &-header {
        height         : 76px;
        display        : flex;
        align-items    : center;
        justify-content: center;

        .logo {
            max-width: 160px;
        }
    }

    &-footer {
        padding: 10px 20px;
    }

    &-content {
        height  : calc(100vh - 118px);
        overflow: auto;
        padding : 60px 0;

        .ant-card {
            box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.12);

            &-head {
                text-align      : center;
                font-size       : 20px;
                background-color: @layout-header-background-dark;

                &-title {
                    font-family: @font-bd;
                    color      : @white-color;
                }
            }

            .ant-btn {
                min-width: 120px;
            }
        }
    }

    &-loginContent {
        .site-card-wrapper {
            max-width: 400px;
            margin   : 0 auto;
        }
    }

    &.clinicLogin{
        .authLayout{
            &-header{
                justify-content: space-between;
            }
            &-content{
                height: calc(100vh - 124px);
                padding-left:15px; padding-right: 15px;
            }
        }
        .authpage-footer{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: solid 1px #e8e8e8;
            .links {
                margin: 0 -20px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                list-style:none;
                li{
                    padding: 0 20px;
                    a{
                        color: @black-color;
                        transition: all ease-in-out .3s;
                        &:hover, &:active, &:focus{
                            color: @secondary-color;
                        }
                    }
                    &.active {
                        a{
                            color: @secondary-color;
                        }
                    }
                }
            }
        }
        .clinic{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            &-wrap{
                box-shadow: 0px 0px 62px 0px rgba(0, 0, 0, 0.07); 
                max-width: 1140px;width:100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: @white-color;
            }
            &-left{
                width: 50%;
            }
            &-right{
                width: 50%;
                padding: 50px;
                .logo {
                    text-align: center;
                    margin-bottom: 25px;
                    img{
                        width: 160px;
                    }
                }
            }
            .ant-card{
                box-shadow: none;
                &-head{
                    border-bottom: none;
                    padding: 0;
                    background-color: transparent;
                    &-title{
                        font-family: @font-bd;
                        color: @black-color;
                        margin-bottom: 15px;
                    }
                }
                &-body{
                    padding: 0;
                }
            }
        }
    }

    .contentBox{
        box-shadow: 0px 0px 62px 0px rgba(0, 0, 0, 0.07);
        max-width: 1140px;
        width: 100%;
        margin: 0 auto;
        background-color: @white-color;
        padding: 30px;
        > h5 {
            font-size: 14px;
            margin-bottom: 15px;
            margin-top: 30px;
            font-family: @font-sm;
            color: @black-color;
            &:first-child{
                margin-top: 0;
            }
        }
        p{
            line-height: 28px;
        }
        &-title{
            font-family: @font-bd;
            font-size: 18px;
            margin-bottom: 30px;
        }
        .ant-collapse{
            border: none;
            background-color: transparent;
            > .ant-collapse-item{
                border: none;
                &:not(:last-child){
                    border-bottom: 1px solid #d9d9d9;
                }
                > .ant-collapse-header{
                    padding: 15px 0;
                }
            }
            .ant-collapse-content{
                border: none;
                > .ant-collapse-content-box {
                    padding: 0 0 15px;
                }
            }
            .ant-collapse-header{
                font-family: @font-sm;
                color: @black-color;
            }
        }
        .licensesList {
            padding-right:52px !important;
            .addOption{right:12px; position: absolute; top: 26;}
        }
    }

    .forgetLink {
        margin-bottom: 20px;
        min-height: 36px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .bottomLink{
        margin-top: 20px;
    }

    .forgetLink, .bottomLink{
        a{
            color: @black-color;
            &:hover, &:focus, &:active{
                color: @secondary-color;
            }
        }
    }


    .submitBtn{
        .ant-btn{
            min-width: 130px;
        }
    }
    @media screen and (max-width: @screen-lg-max) {
        &-header {
            height: 65px;
        }
        &-content {
            padding: 45px 0;height  : calc(100vh - 113px);
        }
        &.clinicLogin{
            .authLayout{
                &-content{
                    height: calc(100vh - 113px);
                }
            }
            .authpage-footer{
                padding: 14px 25px;
                .links {
                    margin: 0 -10px;
                    li{padding: 0 10px;}
                }
            }
            .clinic{
                padding: 0 15px; 
                &-right{
                    padding:35px;
                }
                
            }
        }

        .contentBox{padding: 25px;
            .contentBox-title{margin-bottom: 25px;}
        }
    }
    @media screen and (max-width: @screen-md-max) {
        &.clinicLogin{
            .clinic{
                &-right{
                    padding:15px 25px;
                }
                &-wrap{
                    .ant-card-head{font-size: 17px; min-height: inherit;
                        &-title{ padding: 0;}
                    }
                    .ant-form-item{margin-bottom: 15px;}
                    .forgetLink,.ant-form-item-control-input{min-height:inherit;}
                    .ant-input{padding: 8px 10px;}
                }
            }
        }
    }
    @media screen and (max-width: @screen-sm-max) {
        &-header {
            height: 60px;

            .logo {
                max-width: 120px;
            }
        }

        &-content {
            padding: 35px 0;height  : calc(100vh - 107px);
            .ant-card{
                &-head{
                    font-size: 16px;
                }
                &-body{
                    padding: 15px;
                }
            }
        }
        &.clinicLogin{
            .authLayout{
                &-content{
                    height: calc(100vh - 107px);
                }
            }
            .clinic{
                padding-left: 0; padding-right: 0;
                &-left{display: none;}
                &-right{
                    width: 70%;
                    padding:20px 25px;
                }
            }
            .authpage-footer{
                padding: 14px 15px;
                .links {
                    margin: 0 -7px;
                    li{padding: 0 7px;}
                }
            }
        }
        &-loginContent {
            .site-card-wrapper {
                max-width:290px;
            }
        }
        .contentBox{padding: 20px 15px;
            .contentBox-title{margin-bottom: 20px; font-size:16px;}
        }
    }
    @media screen and (max-width: @screen-xs-max) {
        &-header {
            height: 50px;
        }
        &-content {
            height: calc(100vh - 98px); padding: 25px 0;
            .ant-card{
                &-head{
                    &-title{padding: 13px 0;}
                }
            }
        }
        &.clinicLogin{
            .authLayout{
                &-content{
                    height: calc(100vh - 126px);
                }
            }
            .clinic{
                &-right{
                    width: 100%; padding:20px 15px;
                }
            }
            .authpage-footer{
                flex-wrap: wrap; text-align: center;
                .links {
                    padding: 0; width: 100%; justify-content:center;
                }
                .copyright{width: 100%; margin-top: 10px; order: 2;}
            }
            .bottomLink{
                font-size:13px;
            }
        }
    }
    @media (max-width:479px){
        &.clinicLogin{
            .authpage-footer{
                .links {
                    margin: 0 -4px;
                    li{padding: 0 4px;}
                }
            }
        }
    }
}