/*Base Styles*/
body {
  overflow: hidden;
  .transition(all 0.3s ease-out);
  width: 100% !important;
  position: relative;
  font-family: 'Montserrat-Medium', sans-serif;    
}

#root {
  height: 100vh;
  
}

.pointer,
.link {
  cursor: pointer;
}

a,
.link {
  color: @primary-color;

  &:hover,
  &:focus {
    color: @primary-color;
  }
}

a:focus {
  text-decoration: none;
}

ol, ul, dl {
  padding-left: 30px;
  margin-bottom: 10px;
}

img {
  max-width: 100%;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.minw50 {
  min-width: 50px;
}

.border {
  border: @border-style-base @border-width-base @border-color;
}

.border-2 {
  border: @border-style-base 2px @border-color;
}

.border-top {
  border-top: @border-style-base @border-width-base @border-color;
}

.border-bottom {
  border-bottom: @border-style-base @border-width-base @border-color;
}

.border-bottom-2 {
  border-bottom: @border-style-base (@border-width-base + @border-width-base) @border-color;
}

.border-left {
  border-left: @border-style-base @border-width-base @border-color;
}

.border-right {
  border-right: @border-style-base @border-width-base @border-color;
}

.border-0 {
  border: 0 none !important;
}

.page-heading {
  margin-bottom:24px;

  @media screen and (max-width: @screen-xs-max) {
    margin-bottom:20px;
  }
}

.page-title {
  font-size: @h2-font-size;
  margin-bottom: 0;
}

.size-200 {
  height: @size-200 !important;
  width: @size-200 !important;
  line-height: @size-200 !important;
}

.size-150 {
  height: @size-150 !important;
  width: @size-150 !important;
  line-height: @size-150 !important;
}

.size-120 {
  height: @size-120 !important;
  width: @size-120 !important;
  line-height: @size-120 !important;
}

.size-100 {
  height: @size-100 !important;
  width: @size-100 !important;
  line-height: @size-100 !important;
}

.size-90 {
  height: (@size-100 - 10) !important;
  width: (@size-100 - 10) !important;
  line-height: (@size-100 - 10) !important;
}

.size-80 {
  height: @size-80 !important;
  width: @size-80 !important;
  line-height: @size-80 !important;
}

.size-60 {
  height: @size-60 !important;
  width: @size-60 !important;
  line-height: @size-60 !important;
}

.size-50 {
  height: @size-50 !important;
  width: @size-50 !important;
  line-height: @size-50 !important;
}

.size-40 {
  height: @size-40 !important;
  width: @size-40 !important;
  line-height: @size-40 !important;
}

.size-36 {
  height: @size-36 !important;
  width: @size-36 !important;
  line-height: @size-36 !important;
}

.size-30 {
  height: @size-30 !important;
  width: @size-30 !important;
  line-height: @size-30 !important;
}

.size-24 {
  height: @size-20 + 4px !important;
  width: @size-20 + 4px !important;
  line-height: @size-20 + 4px !important;
}

.size-20 {
  height: @size-20 !important;
  width: @size-20 !important;
  line-height: @size-20 !important;
}

.size-15 {
  height: @size-20 - 5px !important;
  width: @size-20 - 5px !important;
  line-height: @size-20 - 6px !important;
}

.size-10 {
  height: @size-10 !important;
  width: @size-10 !important;
  line-height: @size-10 !important;
}

.size-8 {
  height: @size-8 !important;
  width: @size-8 !important;
  line-height: @size-8 !important;
}

//only width
.width-175 {
  width: @size-175 !important;
}

.img-fluid,
.img-thumbnail {
  max-width: 100%;
  height: auto;
}

.img-fluid-full {
  width: 100%;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: @screen-sm-min) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: @screen-md-min) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: @screen-lg-min) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: @screen-xl-min) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.top-0 {
  top: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.left-0 {
  left: 0 !important;
}

.right-0 {
  right: 0 !important;
}

/*Embed Responsive Classes*/
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;

  &:before {
    display: block;
    content: "";
  }

  & .embed-responsive-item,
  & iframe,
  & embed,
  & object,
  & video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.embed-responsive-21by9 {
  &:before {
    padding-top: 42.8571428571%;
  }
}

.embed-responsive-16by9 {
  &:before {
    padding-top: 56.25%;
  }
}

.embed-responsive-4by3 {
  &:before {
    padding-top: 75%;
  }
}

.embed-responsive-1by1 {
  &:before {
    padding-top: 100%;
  }
}

/*Media Object Classes*/
.media {
  .flex-display(flex, row, wrap);
  .align-items(flex-start);

  &-body {
    .flex-only(1);
  }
}

/*Overflow styles*/
.overflow-auto {
  overflow: auto;
}

.z-index-20 {
  position: relative;
  z-index: 20;
}

/*Hr style*/
hr {
  border-color: fade(@grey-2, 20%);
  margin: 15px 0;
}

/*Status style*/
.status-pos {
  position: relative;

  & .status {
    display: block;
    position: absolute;
    left: 0;
    top: 2px;
    z-index: 1;
    width: 12px;
    height: 12px;
    .border-radius(@border-radius-circle);

    &.small {
      width: 8px;
      height: 8px;
    }

    &.status-rtl {
      top: -4px;
      left: auto;
      right: -4px;
    }

    &.online {
      background-color: @green-color;
      .ct-online-effect;
    }

    &.offline {
      background-color: @white-color;
      border: @border-style-base @border-width-base @green-color;
      .ct-online-effect;
    }

    &.away {
      background-color: @yellow-color;
      .ct-away-effect;
    }

    &.orange {
      background-color: @orange-color;
      .ct-orange-effect;
    }
  }
}

// Box Shadow
.box-shadow {
  .box-shadow(@ct-card-shadow);
}

.no-box-shadow {
  .box-shadow(none);
}

// Border radius
.rounded-xs {
  .border-radius(@border-radius-sm - 2px) !important;
}

.rounded-sm {
  .border-radius(@border-radius-sm) !important;
}

.rounded-base {
  .border-radius(@border-radius-base) !important;
}

.rounded-lg {
  .border-radius(@border-radius-lg) !important;
}

.rounded-xxl {
  .border-radius(@border-radius-xxl) !important;
}

.rounded-circle {
  .border-radius(@border-radius-circle) !important;
}

.border-radius-top-left-0 {
  -moz-border-top-left-radius: 0;
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
}

.border-radius-top-right-0 {
  -moz-border-top-right-radius: 0;
  -webkit-border-top-right-radius: 0;
  border-top-right-radius: 0;
}

.border-radius-bottom-left-0 {
  -moz-border-bottom-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
}

.border-radius-bottom-right-0 {
  -moz-border-bottom-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
}

// Vertical Align
.vertical-align-top {
  vertical-align: top;
}

.vertical-align-middle {
  vertical-align: middle;
}

.vertical-align-bottom {
  vertical-align: bottom;
}

.separator {
  background-color: @primary-color;
  width: @size-20;
  height: 2px;
  margin: 0 auto 10px;
}

.loader-400 {
  height: 400px;

  @media screen and (max-width: @screen-lg-max) {
    height: 300px;
  }
}

.object-cover {
  object-fit: cover;
}

.cke_editable,
.cke_editor_editor2 {
  font-family: @font-family !important;
}

.top2 {
  position: relative;
  top: 2px;
}

.ant-collapse-content-box {
  & > :last-child {
    margin-bottom: 0;
  }
}

.react-stickies-wrapper {
  padding-bottom: 16px;
}

// Hide on 1366
@media screen and (max-width: 1366px) {
  .gx-hide-1366 {
    display: none;
  }
}
