
.uploadImage{
    .ant-form-item-control{
        height: 110px; width : 110px; border: 2px solid @secondary-color;
        background:#e9e9e9;
        .ant-upload{
            &.ant-upload-drag{
                height:30px;width:30px;  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3); border-radius: 50%; background: @secondary-color;
                position: absolute; right: 0; bottom:5px; border:0; z-index: 1;
                &:after{
                    content: "\60"; font-family: "gaxon"; position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%);
                    color:#fff; font-size:12px;
                }
                input{position: absolute; left: 0; top: 0; height: 100%; width: 100%; z-index: 1;opacity: 0; display: block !important; cursor: pointer;}
            }
        }
        .ant-upload-list{
            .ant-upload-list-item{height:106px; border: 0; border-radius:0; padding: 0; margin: 0;}
            .ant-upload-list-item-name{display: none;}
            .picture{display: none;}
            .ant-upload-list-item-thumbnail{height: 100%; width: 100%; opacity:1; line-height:normal;
                img{height: 100%; width: 100%;}
            }
            .ant-upload-list-item-progress{
                position: absolute;
                bottom: auto;
                top: 50px;
                padding: 0;
                left:1px;
                .ant-progress-text{margin-left:5px;}
            }
            .anticon.anticon-loading.anticon-spin {
                position: absolute;
                top: 18px;
                left: 34px;
            }
        }
        .ant-form-item-control-input{position:static;}
    }
}
.boxHeading{
    margin-bottom: 20px;
    h4{font-size: 18px; margin: 0; font-weight:600;}
    @media screen and (max-width: @screen-md-max) {
        margin-bottom: 15px;
        h4{font-size:16px;}
    }
    @media screen and (max-width: @screen-md-max) {
        h4{font-size:15px;}
    }
}

::-webkit-scrollbar {
    width: 6px;height: 6px;
    @include media-breakpoint-down(lg) {
    	width: 4px;height: 4px;
    }
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: none; background-color: #e2e5e6;
}

::-webkit-scrollbar-thumb {
    -webkit-box-shadow: none; background-color: @primary-color;
}
.shadow-none{box-shadow:none !important;}
.ant-empty{
    color:red; margin: 0 auto;
}
//color box
.blue-color{color:@primary-color;}
.dark-blue-color{color:@layout-header-background-dark;}
a{
    &.dark-blue-color{color:@layout-header-background-dark;
        &:hover{color:darken(@layout-header-background-dark, 5%);}
    }
}

.ant-rate{
    color:#fb0;
}
.removeBtn{
    display: flex; align-items: center; justify-content:center;
}
.font-bold,.font-bd{font-weight:700;}
.font-bold{font-weight:600;}
//loader
.listLoader{
    padding: 15px; text-align:center;
    span{color:#38368b; font-size:25px;}
}

.flex-wrap {
    flex-wrap: wrap !important;
}

a[disabled],button[disabled]{
    cursor: not-allowed;
    &:hover,&:focus{color:rgba(0, 0, 0, 0.25);}
}
.loadOption{
    a{border-top:1px solid rgba(0, 0, 0, 0.06); margin-top: 3px;}
}
.ant-notification-topRight{
    .ant-notification-notice-content{
        padding-right: 20px;
    }
}