.appointments{
    background:@white-color; 
    padding: 30px; 
    box-shadow:0 8px 11px rgba(#c2c2c2,0.16);
    .appointmentBox{ 
        margin-bottom: 25px;
        box-shadow:0 0px 6px rgba(@black-color,0.1); 
        border-radius:5px; 
        background:@white-color;
        height: calc(100% - 25px);
        display: flex;
        flex-direction: column;
        position: relative;
        &-fav{
            .appointmentBox__top{
                h4{
                    padding-right: 20px;
                }
            }
        }
        &-badge{
            margin-top: 10px;
            .ant-badge{
                margin-bottom: 0;
            }
        }
        .likeBtn {
            position: absolute;
            right: 0;
            top: 0;
            display: inline-block;
            padding: 8px 10px 10px;
            font-size: 18px;
            z-index: 1;
            color: #c1bbbb;
            border: none;
            box-shadow: none;
            &.active{
                color: @secondary-color;
            }
        }
        &__top{
            padding:15px 15px 15px 80px; position: relative;
            img{height: 50px; width: 50px; position: absolute; left: 15px; top: 15px;
                + span{
                    position: absolute;left: 17px; top: 13px; display: inline-block;
                    width: 13px; height: 13px; border-radius: 50%;
                }
            }
            h4{
                font-size:16px; 
                font-weight:600;
                a{
                    color: @black-color;
                }
            }
            span{font-size:12px;
                &.active{
                    background-color:#59C203; border: 2px solid #ffffff;
                }
                &.away{
                    background-color:#b5b2b1; border: 2px solid #ffffff;
                }
            }
        }
        .consultation{
            margin-top:10px;
            p{
                margin: 0; 
                color:@secondary-color;
                i{
                    font-size:16px; 
                    vertical-align: middle; 
                    margin-right:7px;
                    position: relative;
                    top: -1px;
                    &.ico-message-square, &.ico-user{
                        font-size: 14px;
                    }
                    &.ico-phone{
                        top: -2px;
                    }
                }
            }
            .phoneCall{
                height: 35px; 
                width: 35px; 
                min-height: 35px;
                min-width: 35px; 
                border-radius: 50%; 
                background: @secondary-light-color;
                color:@secondary-color;  
                font-size: 16px; 
                display: flex; 
                justify-content: center;  
                align-items: center;
                .ico-message-square, .ico-user{
                    font-size:14px;
                }
            }
        }
        .speciality{
            margin-bottom: 10px;
            color: @menu-item-group-title-color;
            font-size: 13px;
            em{margin-right: 7px;}
        }
        &__footer{
            padding: 15px; 
            border-top:1px solid #F7F8F9;
            margin-top: auto;
        }
        .action{
            display: flex;
            margin-bottom: 10px;
            justify-content: space-between;
            &-right{
                min-width: 30px;
                text-align: right;
                .anticon{
                    font-size: 20px;
                    color: @black-color;
                }
            }
        }
        .ant-badge{
            &.pending{
                .ant-badge-count{
                    background:#F77E49;
                }
            }
            &.accept{
                .ant-badge-count{
                    background:#38368b;
                }
            }
            &.completed{
                .ant-badge-count{
                    background:#038fde;
                }
            }
            &.entered{
                .ant-badge-count{
                    background:#e91e63;
                }
            }
        }
    }
    @media (max-width:1599px) {
        padding: 25px;
        .appointmentBox{ 
            &__top{
                h4{font-size:15px;}
            }
        }
    }
    @media (max-width:1439px) {
        padding: 20px;
        .appointmentBox{ margin-bottom: 20px; height:calc(100% - 20px);
            &__top{
                padding:15px 15px 15px 70px;
                img{height: 40px; width: 40px;}
            }
        }
    }
    @media screen and (max-width: @screen-lg-max) {
        padding: 15px;
        .appointmentBox{
            &__top{
                padding:15px 15px 15px 65px;
                h4{font-size:14px; margin-bottom: 2px;}
            }
        }
    }
    @media screen and (max-width: @screen-sm-max) {
        .appointmentBox{ margin-bottom: 15px; height:calc(100% - 15px);
            &__top{
                h4{font-size:13px;}
            }
            .consultation{
                margin-top:7px;
                p{ font-size: 13px;
                    i{
                        font-size:14px; margin-right:3px; vertical-align:middle;
                        &.ico-message-square, &.ico-user{
                            font-size: 13px;
                        }
                        &.ico-phone{top: 0;}
                    }
                }
                .phoneCall{
                    height: 30px; width: 30px; min-height: 30px; min-width: 30px; font-size: 14px; 
                    .ico-message-square, .ico-user{
                        font-size:13px;
                    }
                }
            }
            &__footer{
                .ant-btn{height: 32px; font-size:13px; line-height:29px; padding-top: 0; padding-bottom: 0;}
            }
        }
    }
    @media screen and (max-width: @screen-xs-max) {
        .appointmentBox{
            &__top{
                padding:10px 10px 10px 55px;
                img{height:35px; width:35px; left: 10px; top: 10px;
                    + span{
                       left: 10px; top: 10px;  width: 10px; height: 10px; border-width:1px !important;
                    }
                }
                span{font-size: 11px;}
            }
            .consultation{
                margin-top:7px;
                p{ font-size: 12px;}
            }
            &__footer{padding: 10px;}
        }
    }
}
.apppointmentDropdown{
    .btnLink{
        color: @menu-item-group-title-color;
        height: auto;
        border: none;
        background-color: transparent;
        padding: 0;
        &:hover, &:active, &:focus{
            color: @menu-item-group-title-color;
            box-shadow: none;
        }
    }
}
