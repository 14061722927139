.prescriptionPage{
    .hospitalLogo{
        img{max-width:100px;}
    }
    .hospitaDetail{ margin-bottom: 25px;
        h4{
            font-size:20px; margin-bottom:7px; font-weight:600; color:@layout-header-background-dark;
        }
        p{margin: 0;}
    }
    .madicineBox{background: #F7F8F9; padding: 20px; padding-bottom: 0; border-radius: 5px; margin-bottom: 20px;}
    .prescriptionBtn{color:@layout-header-background-dark; height:auto; outline:none !important;
        &--remove{color:@secondary-color;}
    }
}