.messagePage{margin-bottom: 30px;
    &__left{
        background:@white-color; height: 100%;
        &__head{padding: 15px 10px;}
        .groupChat{margin-top: 20px;
            h4{font-weight:600;}
            .ant-dropdown-trigger{font-size:16px; color:@black-color;}
        }
        .ant-input-search{
            .ant-input{border-radius:0; border:0 ; height: 46px; box-shadow: none !important; background:transparent;
                &:focus{box-shadow: none !important;}
                &::-webkit-input-placeholder {color: #535353;}
                &:-moz-placeholder           {color: #535353;}
                &::-moz-placeholder          {color: #535353;}
                &:-ms-input-placeholder{color: #535353;}
            }
            .ant-btn{
                border-radius:0 !important; background:transparent !important; border:0 !important;
                box-shadow:none !important; color:@black-color !important; font-size:20px;
                &:hover,&:focus,&:focus-within{background:transparent !important; box-shadow:none !important;
                &:after{display: none;}
                }
            }
            .ant-input-group{background:#F7F8F9;}
        }
        .userList{
            max-height:calc(100vh - 338px);overflow-x: hidden;overflow-y: auto;padding: 0px; margin: 0;
            li{
                padding: 15px 10px; display: block;
                position: relative;z-index: 1;
                // &:before{
                //     content:"";width:0;height:100%;background:@primary-color;
                //     position:absolute;left:-15px;top:0;z-index:-1; 
                // }
                &:not(:last-child){border-bottom: 1px solid #E2E2E2;}
                &.active,&.activeRoom{
                    background:@primary-color;
                    &:before{width:100%;}
                    .profile{
                        .media{
                            .media-body{
                                h6, p{color:@white-color;}
                            }
                        }
                    }
                }
                .profile{
                    &__img{
                        width:36px;height:36px;min-width: 36px;margin-right: 14px; font-size: 0;
                        .status{
                            width:9px;height:9px;background-color: #ECECEC;
                            position: absolute;right:0;top:0;
                            &.online{background-color: #43D881;}
                        }
                        img{height: 100%; width: 100%; border-radius: 50%;}
                    }
                    .media{max-width:100%; padding-right: 25px;
                        .media-body{
                            max-width: calc(100% - 50px);
                            h6{margin-bottom:5px;font-size:14px;font-weight:600;}
                            p{color:@heading-color;font-size: 12px;}
                        }
                    }
                    &__right{
                        min-width: 55px;text-align: right;margin-left: 10px;
                        .time{color: #BFBFBF;font-size:12px;}
                        .count{
                            min-width: 20px;height: 20px;font-size:12px;border-radius: 4px;
                            background: @secondary-color;margin-left: auto;margin-top: 4px;
                            padding: 2px 5px;
                        }
                    }
                    .msgCound{
                        position: absolute; right: 0; height: 20px; width: 20px; border-radius:50%; font-size: 11px; color:@white-color;
                        top: 50%; transform: translate(0,-50%); background:@layout-header-background-dark; display: flex; align-items:center; justify-content:center;
                    }
                }
                .alert{margin-bottom: 0;font-size: 14px;padding: 7px 10px;}
            }
        }
    }
    &__right{
        width: 100%;height: 100%; min-height:calc(100vh - 220px); position: relative;
        .card-body{
            padding: 0;height:calc(100vh - 385px); overflow:auto;
            .msg-wrap{
                max-height:100%;overflow-y: auto;overflow-x: hidden;padding: 20px;
                .msg-body{
                    display: inline-block;max-width:385px;margin-bottom: 20px;
                    &.fileAttached{
                        @media (min-width: 1500px){
                            max-width: 485px;
                        }
                    }
                    .img_wrap{
                        bottom:20px;width: 45px;height: 45px;
                        img{width: 100%;}
                    }
                    .msg{
                        span{
                            padding:15px;color:@black-color; word-break:break-all;
                            &.bgGray{background-color:rgba(186,187,194,0.1);}
                        }
                        + .msg{margin-top: 15px;}
                    }
                }
                .downloadBtn{
                    width: 50px;height: 50px;font-size: 20px;display: flex; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);
                    align-items: center;justify-content:center; color:@white-color; background-color:rgba(@black-color, 0.4);
                    &:hover{opacity: 0.8; }
                }
                .document{
                    width: 240px; box-shadow: 0 8px 11px rgba(194,194,194,0.16); overflow: hidden;
                    background-color: #f6f4f4; border-radius: 10px;
                    &__inner{padding:15px;
                        img{max-width:50px;}
                    }
                    .btn{display: block; padding: 8px;}
                }
                .videoThumb{
                    border-radius: 5px; overflow: hidden; min-width:100px; min-height:100px;
                    &__time{
                        i{font-size: 20px; vertical-align: middle; margin-right: 3px;}
                        font-size: 15px; color:@white-color; position: absolute; left:20px; bottom:15px;
                    }
                }
                .image{
                    border-radius: 5px; min-width:100px; min-height:100px;
                    //img{filter:blur(5px);}
                    .downloadBtn{
                        display: flex;
                        align-items: center;justify-content:center;
                        color:@white-color; background-color:rgba(@black-color, 0.4);
                        &:hover{opacity: 0.8; }
                    }
                }
                .rcv-msg{
                    .img_wrap{left:0;top: 0; overflow: hidden;}
                    .chat_box{padding-left:55px;}
                    span{border-radius:25px 25px 25px 0; background:rgba(143,215,246,0.1);}
                }
                .send-msg{
                    text-align: right;
                    .img_wrap{right:0;top: 0;}
                    .chat_box{padding-right:55px;}
                    .msg-body{
                        .msg{
                            span{text-align:left;}
                        }
                    }
                    span{border-radius:25px 25px 0 25px;}
                }
                .dateTime{
                    display: flex; justify-content:space-between; align-items:center; margin-top: 8px; min-width:200px;
                    span{padding: 0 !important; font-size: 12px;}
                }
            }
            .chatDate{text-align:center; position: relative; margin-bottom: 15px;
                &:after{content:""; position: absolute; left: 0; width: 100%; height: 1px; background:#E9E9E9; top:9px;}
                span{background:@white-color; position: relative;z-index: 1; padding: 0 20px;}
                
            }
        }  
        .card-footer{
            padding: 15px 20px;
            .typingMsg{margin-bottom: 15px; display: block;}
            .footerSearch{border:1px solid #BCBCBC;padding: 10px; position: relative; padding-left: 15px;}
            .fileAttached{ width: 30px;
                left:10px;z-index: 1; position: relative; overflow: hidden; margin-right: 13px;
                &__btn{
                    line-height:20px;margin-bottom:0;font-size:18px;color:@text-color;
                    cursor:pointer;
                    &:hover{color:@primary-color;}
                }
                input{position: absolute; opacity: 0; left: 0; height: 100%; width: 100%;}
                .ant-upload{
                    &.ant-upload-drag{position: absolute; top: 0; opacity:0;}
                }
                .ant-upload-list{display: none;}
            }
            textarea{
                resize: none;font-size:15px; height: 38px;padding: 8px; width: 100%; outline:none; box-shadow:none;
            }
            .options{
                min-width:100px;text-align: right;
                .btn{
                    font-size: 15px;height: 38px;margin-left: auto;
                    i{margin-right: 5px;}
                }
            }
        }
        .messageApproval{
            position: absolute; left: 0; top:71px; width: 100%; height: calc(100% - 71px); z-index: 1; background:@white-color; display: flex; flex-direction:column;
            padding:0 15px; text-align:center;
        }
        .ant-empty{display:flex; flex-direction:column; justify-content:center; align-items:center; height:100%;}
    }
    &__head{
        padding: 15px 20px;border-bottom: 1px solid #E9E9E9;
        .headLeft{ list-style:none; padding: 0; margin: 0; display: flex; align-items: flex-start;
            img{max-width:40px;max-height:40px; min-width:40px; min-height:40px; border-radius:50%;}
            h6{font-size: 16px;color: @black-color;margin-bottom:0px; margin-left: 15px; width: auto; max-width:90px;}
            li{display: flex; align-items:center;
                &:not(:last-child){margin-right:20px;}
                .ant-dropdown-trigger{
                    border:2px solid @primary-color; width:40px;height:40px;border-radius:50%;
                    display: flex; align-items:center; justify-content:center;
                    .anticon-plus{font-size:12px;}
                }
                .imgUpload{
                    position: relative;
                    > span{
                        position: absolute; left: 0; top: 0; background:#00000054; height: 100%; width: 100%; border-radius:50%;
                        display: none;
                        .ant-upload{border-radius:50%; border: 0;
                            &.ant-upload-btn{
                                &:after{
                                    content: "\e0a1"; font-family: "gaxon"; position: absolute; left: 50%; top: 50%;
                                    transform: translate(-50%,-50%);
                                }
                            }
                        }
                    }
                    .ant-btn{border:0; background:transparent; color:@white-color;}
                    &:hover{
                        span{display: block;}
                    }
                    .ant-upload-list{display: none;}
                }
            }
            .showInput{color:@secondary-color; font-size:18px;}
            .editName{
                position: relative; margin-left: 10px;
                padding-right: 25px;
                input{border-radius:0;}
                a{
                    position: absolute;right: 0; top: -2px; color:@secondary-color;
                    + a{
                        top: 18px;
                    }
                }
                .submitbtn {
                    position: absolute; right: 0; top: 18px;
                    .ant-btn{ padding: 0; border: 0;  height: auto; color: @secondary-color;}
                }
            }
        }
        .headRight{
            .ant-dropdown-trigger,.ant-dropdown-link{height: 40px; width: 40px; display: block;}
            img{height: 100%; width: 100%; border-radius:50%;}
        }
    }
    @media screen and (max-width: @screen-md-max) {
        &__left{ margin-bottom: 15px;
            .groupChat{margin-top:15px;}
            .ant-input-search{
                .ant-input{height: 40px;}
                .ant-btn{font-size:18px;}
            }
            .userList{
                max-height:inherit;display: flex; overflow: auto; border-top:1px solid #E2E2E2;
                li{
                    padding: 10px; border-right: 1px solid #E2E2E2; max-width:250px;
                }
            }
        }
        &__right{
            min-height:calc(100vh - 357px);
            .card-body{
                max-height:500px;
                .msg-wrap{
                    padding: 20px 15px;
                    .msg-body{
                        max-width:300px;margin-bottom:15px;
                        .img_wrap{
                            bottom:15px;width:40px;height: 40px;
                        }
                        .msg{word-break:break-all;
                            span{ padding:8px 10px;font-size: 13px;}
                            + .msg{margin-top: 10px;}
                        }
                    }
                    .downloadBtn{
                        width: 40px;height: 40px;font-size:17px; 
                    }
                    .document{
                        width: 220px;
                        &__inner{padding:15px;
                            img{max-width:35px;}
                        }
                    }
                    .videoThumb{ min-width:80px; min-height:80px;
                        &__time{
                            i{font-size:17px; margin-right: 2px;}
                            font-size: 14px;left:10px; bottom:10px;
                        }
                    }
                    .image{
                        min-width:80px; min-height:80px;
                    }
                    .rcv-msg{
                        .chat_box{padding-left:50px;}
                    }
                    .send-msg{
                        .chat_box{padding-right:50px;}
                    }
                }
            }
            .messageApproval{
                h2{font-size:18px;}
            }
        }
    }
    @media screen and (max-width: @screen-sm-max) {
        margin-bottom: 20px;
    }
    @media screen and (max-width: @screen-xs-max) {
        &__head{
            padding: 10px;
            .headLeft{ flex-wrap:wrap;
                img{min-width:35px;min-height:35px;max-width:35px;max-height:35px;}
                h6{font-size: 14px; margin-left:5px;}
                li{display: flex; align-items:center;position: relative;
                    &:not(:last-child){margin-right:10px; margin-bottom: 10px;}
                    .ant-dropdown-trigger{
                        border:1px solid @primary-color; width:35px;height:35px;
                        .anticon-plus{font-size:11px;}
                    }
                }
                .showInput{font-size:15px;}
                .editName{
                    position: absolute; margin: 0; padding: 10px; background:@white-color; left: 0; top: 47px;
                    padding-right: 30px; width: 250px; box-shadow:0 1px 20px rgba(@black-color,0.1); z-index: 1;
                    a{
                       right: 10px; top: 7px;
                        + a{
                            top: 27px;
                        }
                    }
                    &:before,&:after{
                        content: "";position: absolute; border-left: 10px solid transparent; border-right:10px solid transparent;
                        border-bottom:10px solid #E2E2E2; left: 10px; top: -10px;
                    }
                    &:after{
                        border-bottom:11px solid @white-color; top:-9px
                    }
                }
            }
            .headRight{
                margin-left: auto;
                .ant-dropdown-trigger{height: 35px; width: 35px;}
            }
        }
        &__right{
            .card-body{
                max-height:400px;
                .msg-wrap{
                    padding: 15px 10px;
                    .msg-body{
                        max-width:250px;
                    }
                    .downloadBtn{
                        width: 35px;height: 35px;font-size:15px; 
                    }
                    .document{width: 200px;}
                }
            }
            .card-footer{
                padding: 10px;
                .footerSearch{padding-left: 5px;}
                .fileAttached{margin-right: 10px;
                    &__btn{font-size:16px;}
                }
                textarea{font-size:14px;}
                .options{
                    min-width:45px;
                    .ant-btn{padding-left: 10px; padding-right: 10px;}
                }
            }
            .messageApproval{
                h2{font-size:16px;}
            }
        }
    }
}
.chatDropdown{
    .ant-dropdown-menu-item{padding: 5px 15px;
        &:hover{background:@secondary-light-color;}
    }
}
.memberDropdown{
    padding: 10px 0 5px; background:@white-color; box-shadow:0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
        border-radius:6px;
    .ant-input-search{
        padding: 0 12px;
        .ant-input{border-radius:0; height: 42px;}
        .ant-btn{ border-radius:0 !important; height: 42px;}
    }
    li,.memberlist{
        padding: 5px 40px 5px 12px; background: #EEEEF2; position: relative;
        a{position: relative; padding:5px 5px 5px 50px; display: flex; color:#545454; flex-direction:column; justify-content:center; min-height:45px;
            img{height: 40px; width: 40px; border-radius:50%; position: absolute; left: 0;top:5px;}
        }
        h4{font-size:13px; font-weight:600; margin-bottom: 4px; white-space: normal;}
        p{margin: 0; font-size:13px;white-space: normal;}
        &:hover{
            background:@white-color;
        }
        &:after{
            content: "\e918"; font-family: 'icomoon'; position: absolute; right: 12px;
            color: #59C203; z-index: 1; top: 50%; transform: translate(0,-50%); font-size: 20px; display: none;
        }
        &.activeMember{
            background:@white-color;
            &:after{display: block;}
        }
    }
    .member{max-height:260px; overflow:auto; padding: 0 12px;}
    .memberFooter{padding: 10px 12px;}
    @media screen and (max-width: @screen-xs-max) {max-width:275px;}
}