.appointmentPage{margin-bottom: 30px;
    .ant-tabs-nav{
        &:before{display: none;}
        background:@white-color; box-shadow:0 0 20px rgba(@header-text-color,0.07);
        .ant-tabs-tab{padding: 18px 35px; font-size:16px; color:@black-color;
            &-active,&:hover{
                .ant-tabs-tab-btn{color:@secondary-color;}
            }
            + .ant-tabs-tab{border-left:1px solid #F3F3F3; margin-left: 0;}
        }
        .ant-tabs-ink-bar{display: none;}
    }
    .ant-empty{padding-bottom: 30px;}
    @media screen and (max-width: @screen-lg-max) {
        .ant-tabs-nav{
            .ant-tabs-tab{padding: 15px 25px; }
        }
    }
    @media screen and (max-width: @screen-md-max) {
        margin-bottom: 20px;
        .ant-tabs-nav{
            .ant-tabs-tab{padding: 15px 20px; font-size:15px;}
        }
    }
    @media screen and (max-width: @screen-xs-max) {
        .ant-tabs-nav{
            .ant-tabs-tab{padding:13px 11px; font-size:13px;} 
        }
    }
}