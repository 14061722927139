.ant-radio-group {
    .ant-radio-wrapper {
        .ant-radio-inner {
            border-width: 2px;
            border-color: #868686;
            width       : 18px;
            height      : 18px;
        }

        &-checked {
            .ant-radio-inner {
                border-color: @layout-header-background-dark;

                &:after {

                    background-color: @layout-header-background-dark;
                }

            }

            
        }
    }
    .ant-radio-checked {
        &:focus {
            border-color: red;
        }
        &:after{
            border:2px solid  @layout-header-background-dark;
        }
    }
    @media screen and (max-width: @screen-xs-max) {
        .ant-radio-button-wrapper{padding-left: 10px; padding-right: 10px;}
    }
}
