@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?t0uz32');
  src:  url('fonts/icomoon.eot?t0uz32#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?t0uz32') format('truetype'),
    url('fonts/icomoon.woff?t0uz32') format('woff'),
    url('fonts/icomoon.svg?t0uz32#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ico-"], [class*=" ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ico-record-icon:before {
  content: "\e900";
}
.ico-screen-share:before {
  content: "\e901";
}
.ico-whiteboard-icon:before {
  content: "\e902";
}
.ico-nurse-icon:before {
  content: "\e903";
}
.ico-user:before {
  content: "\e904";
}
.ico-group-line:before {
  content: "\e905";
}
.ico-hospital-line:before {
  content: "\e906";
}
.ico-upload:before {
  content: "\e907";
}
.ico-clock:before {
  content: "\e908";
}
.ico-file:before {
  content: "\e909";
}
.ico-email:before {
  content: "\e90a";
}
.ico-statistics:before {
  content: "\e90b";
}
.ico-notepad:before {
  content: "\e90c";
}
.ico-doctor1:before {
  content: "\e90d";
}
.ico-safe:before {
  content: "\e90e";
}
.ico-pharmacy:before {
  content: "\e90f";
}
.ico-phone:before {
  content: "\e910";
}
.ico-dashboard1:before {
  content: "\e911";
}
.ico-doctor:before {
  content: "\e912";
}
.ico-more-v:before {
  content: "\e913";
}
.ico-cog:before {
  content: "\e914";
}
.ico-appointments-fill:before {
  content: "\e915";
}
.ico-wating:before {
  content: "\e916";
}
.ico-star-circle:before {
  content: "\e917";
}
.ico-check:before {
  content: "\e918";
}
.ico-chevron-up:before {
  content: "\e919";
}
.ico-chevron-down:before {
  content: "\e91a";
}
.ico-chevron-left:before {
  content: "\e91b";
}
.ico-chevron-right:before {
  content: "\e91c";
}
.ico-corporate01:before {
  content: "\e922";
}
.ico-appointment:before {
  content: "\e923";
}
.ico-appointment01:before {
  content: "\e924";
}
.ico-comment:before {
  content: "\e928";
}
.ico-map:before {
  content: "\e92f";
}
.ico-dashboard:before {
  content: "\e932";
}
.ico-timer:before {
  content: "\e934";
}
.ico-questionnaire:before {
  content: "\e935";
}
.ico-message-square:before {
  content: "\e936";
}
.ico-video:before {
  content: "\e939";
}
.ico-mic-off:before {
  content: "\e93c";
}
.ico-phone-call:before {
  content: "\e93d";
}
.ico-video-off:before {
  content: "\e940";
}
.ico-send:before {
  content: "\e949";
}
.ico-event:before {
  content: "\e94f";
}
.ico-video1:before {
  content: "\e954";
}
.ico-mic:before {
  content: "\e955";
}
.ico-download:before {
  content: "\e956";
}
.ico-photo:before {
  content: "\e95a";
}
.ico-attached:before {
  content: "\e965";
}
