.listingPage{
    .ant-upload-list{
        margin-bottom: 20px;
        .ant-upload-list-text-container{
            &:not(:last-child){
                margin-bottom: 10px;
            }
            .ant-upload-list-item-name,.ant-upload-text-icon{color:@black-color;}
            
        }
        &-item{
            border: 0; height: auto; padding: 0; border-radius:0;
            &-name,&-thumbnail {color:@black-color;}
            &-done{display:none;}
            &-card-actions{
                .anticon{color:#f5222d;}
            }
            &-name{
                padding-right: 25px !important;
            }
            .ant-upload-list-item-progress{bottom:-10px;}
            
        }
        
    }
    .ant-upload{
        &.ant-upload-drag{
            .ant-upload-btn {
                background: @white-color; border-radius: 5px; padding: 25px 20px;
            }
            @media screen and (max-width: @screen-md-max) {
                .ant-upload-text{font-size:15px;}
            }
            @media screen and (max-width: @screen-sm-max) {
                .ant-upload-text{font-size:14px;}
                .ant-upload-drag-icon{margin-bottom: 15px;
                    .anticon.anticon-inbox{font-size:40px;}
                }
                .ant-upload-btn {
                    background: @white-color; padding:20px 15px;
                }
            }
        }
    }
}