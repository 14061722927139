.ratingPage{
    .topBar{
         color:@black-color; font-weight:600;
		.rating{
			i{color:#D9D9D9;font-size: 18px;	
				&.active{color: #fb0;}
				&:not(:last-child){
					margin-right: 5px;
				}
			}
		}
		.divider{color:#b8bdc0;}
		.ant-rate-star{
			padding: 0;
			&:not(:last-child){
				margin-right: 5px;
			}
			div{transform:scale(1);}
		}
	}
    .ratingBox{padding: 20px; box-shadow:0 0px 6px rgba(@black-color,0.1); margin-bottom: 25px; border-radius:5px; height:calc(100% - 25px);
        .profileImg{
			width:70px; height: 70px; border-radius:10px; 
			padding:5px; border:1px solid @primary-color; margin-right: 10px; 
			img{width: 100%;height: 100%; border-radius:10px; }
		}
		.title{font-weight:600; margin-bottom: 5px; font-size:15px;}
		.date{
			color:@text-color;font-size: 13px;line-height: normal;
		}
		.rating{margin-left: 5px; white-space: nowrap;
			i{
				color: #D9D9D9;
				&.active{color: #fb0;}
				&:not(:last-child){
					margin-right: 5px;
				}
			}
			.ant-rate-star{
				div{transform:scale(1);}
			}
		}
		.description{
			margin-top: 15px;
			p{color:#6a747b; font-size: 13px;}
		}
    }
	@media (max-width:1699px){
		.ratingBox{
			.profileImg{width:60px; height: 60px;}
			.rating{
				.ant-rate-star{
					font-size:18px;
					&:not(:last-child){margin-right: 5px;}
				}
			}
		}
	}
	@media (max-width:1499px){
		.ratingBox{
			.rating{
				.ant-rate-star{
					font-size:16px;
				}
			}
		}
	}
	@media screen and (max-width: @screen-lg-max) {
		.topBar{
			.rating{
				i{font-size: 16px;}
			}
		}
		.ratingBox{padding: 15px; margin-bottom: 20px; height:calc(100% - 20px);
			.profileImg{width:55px; height: 55px;}
			.title{font-size:14px;}
			.rating{
				.ant-rate-star{
					font-size:15px;
					&:not(:last-child){margin-right: 3px;}
				}
			}
		}
	}
	@media screen and (max-width: @screen-sm-max) {
		.topBar{
			margin-top: 15px;
			.ant-rate-star{font-size: 16px;}
			li{
				&:not(:last-child){margin-right: 2px;}
			}
		}
		.ratingBox{margin-bottom:15px; height:calc(100% - 15px) 
			.profileImg{width:40px; height: 40px; padding: 2px;}
			.title{font-size:13px; margin-bottom: 2px;}
			.rating{ white-space: nowrap;
				.ant-rate-star{
					font-size:13px;
					&:not(:last-child){margin-right: 4px;}
				}
			}
		}
	}
}