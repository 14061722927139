.prescriptionModal{
    .prescriptionWrapper{border:1px solid @primary-color;}
    .hospitalImg{
        margin-bottom: 25px; text-align: center; padding:20px 15px 0;
        img{max-width:200px;}
        p{margin: 0;}
    }
    .doctorDetail{
        padding:0 15px;
        h3{font-weight:600; color:@layout-header-background-dark; font-size:16px;}
    }
    .divider{
        border-top: 2px solid #F7F8F9;
        margin: 15px 0;
    }
    h4{color: #000; font-weight:600; margin-bottom: 15px;
        padding: 0 15px;}
    .patientDetail{
        padding: 0 15px;  margin: 0 -5px; display: flex; flex-wrap:wrap;
        .commonList{
            padding: 0 5px;font-size: 14px; margin-bottom: 0;
            flex:1 1 50%; max-width:50%;
            span{ color: #737374; font-weight:400;}
            label{
                font-weight:600; color:#000; display: block; margin-bottom: 5px;
            }
        }
    }
    .commonList{
        color: #737374; padding: 0 15px;font-size: 14px; margin-bottom:7px;
        span{
            font-weight:600; color:#000;
        }
    }
    .medicineList{
        padding:0 15px;
        max-height: 235px;
        overflow-y: auto;
        overflow-x: hidden;
        ul{ display:flex; flex-wrap:wrap; margin:0 -5px;
            padding-left:0;
            li{list-style:none; flex: 1 1 50%;  max-width: 50%; padding: 0 5px; margin-bottom:10px;    
                label{color:#000; font-weight:600; display:block; margin-bottom:5px;}
                ul{
                    &:not(:last-child){
                        border-bottom: 0; padding-bottom: 0; margin-bottom:0;
                    }
                }
            }
            &:not(:last-child){
                border-bottom: 1px solid #F7F8F9;
                margin-bottom: 10px;
                padding-bottom: 5px;
            }
        }
    }
    @media screen and (max-width: @screen-xs-max) {
        .hospitalImg{
            margin-bottom: 15px; text-align: center; padding:15px 10px 0;
            img{max-width:150px;}
        }
        .doctorDetail{ margin-top: 7px;
            padding:0 10px;
            h3{font-size:15px;}
        }
        .patientDetail{padding: 0 10px; }
        .commonList{ padding: 0 10px;}
        .medicineList{
            padding:0 10px;
            max-height: 220px;
            ul{ 
                li{flex: 1 1 100%;  max-width: 100%;}
                &:not(:last-child){
                    margin-bottom: 5px; padding-bottom: 5px;
                }
            }
        }
        .divider{
            border-top: 1px solid #F7F8F9;
            margin: 10px 0;
        }
        h4{padding: 0 10px; font-size: 15px;}
    }
}