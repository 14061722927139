@import "variables";
@import '../../../node_modules/antd/lib/style/color/colors.less';

/* color classes */

// BG ColorPicker
.bg-primary {
  background-color: @primary-color !important;
}

a.bg-primary {
  &:hover,
  &:focus {
    background-color: @primary-color !important;
  }
}

.bg-primary-light {
  background-color: @primary-4 !important;
}

.bg-secondary {
  background-color: @secondary-color !important;
}

a.bg-secondary {
  &:hover,
  &:focus {
    background-color: @secondary-color !important;
  }
}

.bg-success {
  background-color: @success-color !important;
}

a.bg-success {
  &:hover,
  &:focus {
    background-color: darken(@success-color, 10%) !important;
  }
}

.bg-success-dark {
  background-color: darken(@success-color, 15%) !important;
}

a.bg-success-dark {
  &:hover,
  &:focus {
    background-color: darken(@success-color, 20%) !important;
  }
}

.bg-info {
  background-color: @info-color !important;
}

a.bg-info {
  &:hover,
  &:focus {
    background-color: darken(@info-color, 10%) !important;
  }
}

.bg-warning {
  background-color: @warning-color !important;
}

a.bg-warning {
  &:hover,
  &:focus {
    background-color: darken(@warning-color, 10%) !important;
  }
}

.bg-danger {
  background-color: @danger-color !important;
}

a.bg-danger {
  &:hover,
  &:focus {
    background-color: darken(@danger-color, 10%) !important;
  }
}

.bg-light {
  background-color: @grey-4 !important;
}

a.bg-light {
  &:hover,
  &:focus {
    background-color: @grey-5 !important;
  }
}

.bg-dark {
  background-color: @black-color !important;
}

a.bg-dark {
  &:hover,
  &:focus {
    background-color: lighten(@black-color, 10%) !important;
  }
}

.bg-white {
  background-color: @white-color !important;
}

a.bg-white {
  &:hover,
  &:focus {
    background-color: darken(@white-color, 5%) !important;
  }
}

.bg-grey {
  background-color: darken(@grey-5, 5%) !important;
}

a.bg-grey {
  &:hover,
  &:focus {
    background-color: darken(@grey-6, 5%) !important;
  }
}

.bg-light-grey {
  background-color: @grey-2 !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-pink {
  background-color: @pink-color !important;
}

a.bg-pink {
  &:hover,
  &:focus {
    background-color: darken(@pink-color, 10%) !important;
  }
}

.bg-green {
  background-color: @green-color !important;
}

a.bg-green {
  &:hover,
  &:focus {
    background-color: darken(@green-color, 10%) !important;
  }
}

.bg-green-light {
  background-color: lighten(@green-color, 40%) !important;
}

a.bg-green-light {
  &:hover,
  &:focus {
    background-color: lighten(@green-color, 20%) !important;
  }
}

.bg-red {
  background-color: @red-color !important;
}

a.bg-red {
  &:hover,
  &:focus {
    background-color: darken(@red-color, 10%) !important;
  }
}

.bg-amber {
  background-color: @amber-color !important;
}

a.bg-amber {
  &:hover,
  &:focus {
    background-color: darken(@amber-color, 10%) !important;
  }
}

.bg-amber-light {
  background-color: lighten(@amber-color, 30%) !important;
}

a.bg-amber-light {
  &:hover,
  &:focus {
    background-color: lighten(@amber-color, 20%) !important;
  }
}

.bg-blue {
  background-color: @blue-color !important;
}

a.bg-blue {
  &:hover,
  &:focus {
    background-color: darken(@blue-color, 10%) !important;
  }
}

.bg-light-blue {
  background-color: lighten(@blue-color, 5%) !important;
}

a.bg-light-blue {
  &:hover,
  &:focus {
    background-color: lighten(@blue-color, 10%) !important;
  }
}

.bg-indigo {
  background-color: @indigo-color !important;
}

a.bg-indigo {
  &:hover,
  &:focus {
    background-color: darken(@indigo-color, 10%) !important;
  }
}

.bg-purple {
  background-color: @purple-color !important;
}

a.bg-purple {
  &:hover,
  &:focus {
    background-color: darken(@purple-color, 10%) !important;
  }
}

.bg-orange {
  background-color: @orange-color !important;
}

a.bg-orange {
  &:hover,
  &:focus {
    background-color: darken(@orange-color, 10%) !important;
  }
}

.bg-yellow {
  background-color: @yellow-color !important;
}

a.bg-yellow {
  &:hover,
  &:focus {
    background-color: darken(@yellow-color, 10%) !important;
  }
}

.bg-teal {
  background-color: @teal-color !important;
}

a.bg-teal {
  &:hover,
  &:focus {
    background-color: darken(@teal-color, 10%) !important;
  }
}

.bg-cyan {
  background-color: @cyan-color !important;
}

a.bg-cyan {
  &:hover,
  &:focus {
    background-color: darken(@cyan-color, 10%) !important;
  }
}

.bg-sepia {
  background-color: @sepia-color !important;
}

a.bg-sepia {
  &:hover,
  &:focus {
    background-color: darken(@sepia-color, 10%) !important;
  }
}

.bg-geekblue {
  background-color: @geekblue-10 !important;
}

a.bg-geekblue {
  &:hover,
  &:focus {
    background-color: darken(@geekblue-10, 10%) !important;
  }
}

// Text ColorPicker
.text-white {
  color: @white-color !important;
}

.text-primary {
  color: @primary-color !important;
}

a.text-primary {
  &:hover,
  &:focus {
    color: @primary-color !important;
  }
}

.text-secondary {
  color: @secondary-color !important;
}

a.text-secondary {
  &:hover,
  &:focus {
    color: @secondary-color !important;
  }
}

.text-success {
  color: @success-color !important;
}

a.text-success {
  &:hover,
  &:focus {
    color: darken(@success-color, 5%) !important;
  }
}

.text-success-dark {
  color: darken(@success-color, 15%) !important;
}

a.text-success-dark {
  &:hover,
  &:focus {
    color: darken(@success-color, 20%) !important;
  }
}

.text-info {
  color: @info-color !important;
}

a.text-info {
  &:hover,
  &:focus {
    color: darken(@info-color, 5%) !important;
  }
}

.text-warning {
  color: @warning-color !important;
}

a.text-warning {
  &:hover,
  &:focus {
    color: darken(@warning-color, 5%) !important;
  }
}

.text-danger {
  color: @danger-color !important;
}

a.text-danger {
  &:hover,
  &:focus {
    color: darken(@danger-color, 5%) !important;
  }
}

.text-light {
  color: darken(@grey-6, 8%) !important;
}

a.text-light {
  &:hover,
  &:focus {
    color: darken(@grey-7, 8%) !important;
  }
}

.text-light-grey {
  color: darken(@grey-5, 5%) !important;
}

a.text-light-grey {
  &:hover,
  &:focus {
    color: @grey-6 !important;
  }
}

.text-grey {
  color: @grey-7 !important;
}

a.text-grey {
  &:hover,
  &:focus {
    color: @grey-9 !important;
  }
}

.text-dark {
  color: @grey-8 !important;
}

a.text-dark {
  &:hover,
  &:focus {
    color: darken(@grey-8, 5%) !important;
  }
}

.text-black {
  color: @black-color !important;
}

a.text-black {
  &:hover,
  &:focus {
    color: darken(@grey-8, 5%) !important;
  }
}

.text-pink {
  color: @pink-color !important;
}

a.text-pink {
  &:hover,
  &:focus {
    color: darken(@pink-color, 5%) !important;
  }
}

.text-green {
  color: @green-color !important;
}

a.text-green {
  &:hover,
  &:focus {
    color: darken(@green-color, 5%) !important;
  }
}

.text-red {
  color: @red-color !important;
}

a.text-red {
  &:hover,
  &:focus {
    color: darken(@red-color, 5%) !important;
  }
}

.text-amber {
  color: @amber-color !important;
}

a.text-amber {
  &:hover,
  &:focus {
    color: darken(@amber-color, 5%) !important;
  }
}

.text-blue {
  color: @blue-color !important;
}

a.text-blue {
  &:hover,
  &:focus {
    color: darken(@blue-color, 5%) !important;
  }
}

.text-indigo {
  color: @indigo-color !important;
}

a.text-indigo {
  &:hover,
  &:focus {
    color: darken(@indigo-color, 5%) !important;
  }
}

.text-purple {
  color: @purple-color !important;
}

a.text-purple {
  &:hover,
  &:focus {
    color: darken(@purple-color, 5%) !important;
  }
}

.text-orange {
  color: @orange-color !important;
}

a.text-orange {
  &:hover,
  &:focus {
    color: darken(@orange-color, 5%) !important;
  }
}

.text-yellow {
  color: @yellow-color !important;
}

a.text-yellow {
  &:hover,
  &:focus {
    color: darken(@yellow-color, 5%) !important;
  }
}

.text-teal {
  color: @teal-color !important;
}

a.text-teal {
  &:hover,
  &:focus {
    color: darken(@teal-color, 5%) !important;
  }
}

.text-cyan {
  color: @cyan-color !important;
}

a.text-cyan {
  &:hover,
  &:focus {
    color: darken(@cyan-color, 5%) !important;
  }
}

.text-muted {
  color: @grey-7 !important;
}

.text-geekblue {
  color: @geekblue-10 !important;
}

a.text-geekblue {
  &:hover,
  &:focus {
    color: @geekblue-8 !important;
  }
}

.text-sepia {
  color: @sepia-color !important;
}

a.text-sepia {
  &:hover,
  &:focus {
    color: @sepia-color !important;
  }
}

.icon-white {
  color: @white-color !important;
}

// Border ColorPicker
.border-primary {
  border-color: @primary-color !important;
}

.border-secondary {
  border-color: @secondary-color !important;
}

.border-success {
  border-color: @success-color !important;
}

.border-success-dark {
  border-color: darken(@success-color, 15%) !important;
}

.border-info {
  border-color: @info-color !important;
}

.border-warning {
  border-color: @warning-color !important;
}

.border-danger {
  border-color: @danger-color !important;
}

.border-light {
  border-color: @grey-6 !important;
}

.border-dark {
  border-color: @grey-8 !important;
}

.border-white {
  border-color: @white-color !important;
}

.border-grey {
  border-color: @grey-6 !important;
}

.border-pink {
  border-color: @pink-color !important;
}

.border-green {
  border-color: @green-color !important;
}

.border-red {
  border-color: @red-color !important;
}

.border-amber {
  border-color: @amber-color !important;
}

.border-blue {
  border-color: @blue-color !important;
}

.border-indigo {
  border-color: @indigo-color !important;
}

.border-purple {
  border-color: @purple-color !important;
}

.border-orange {
  border-color: @orange-color !important;
}

.border-yellow {
  border-color: @yellow-color !important;
}

.border-teal {
  border-color: @blue-color !important;
}

.border-cyan {
  border-color: @cyan-color !important;
}

.border-sepia {
  border-color: @sepia-color !important;
}

.border-geekblue {
  border-color: @geekblue-10 !important;
}

// Button ColorPicker
.btn-primary,
a.btn-primary {
  color: @white-color !important;
  background-color: @primary-color !important;
  border-color: @primary-color !important;

  &:hover,
  &:focus {
    color: @white-color !important;
    background-color: @primary-color !important;
    border-color: @primary-color !important;
  }

  &.disabled,
  &:disabled {
    background-color: @primary-color !important;
    border-color: @primary-color !important;
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    color: @white-color;
    background-color: @primary-color !important;
    border-color: @primary-color !important;
  }
}

.btn-primary-light,
a.btn-primary-light {
  color: @grey-8 !important;
  background-color: lighten(@primary-color, 45%) !important;
  border-color: lighten(@primary-color, 45%) !important;

  &:hover,
  &:focus {
    color: @grey-8 !important;
    background-color: lighten(@primary-color, 30%) !important;
    border-color: lighten(@primary-color, 30%) !important;
  }

  &.disabled,
  &:disabled {
    background-color: lighten(@primary-color, 50%) !important;
    border-color: lighten(@primary-color, 50%) !important;
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    color: @grey-8;
    background-color: lighten(@primary-color, 40%) !important;
    border-color: lighten(@primary-color, 40%) !important;
  }
}

.btn-secondary,
a.btn-secondary {
  color: @white-color !important;
  background-color: @secondary-color !important;
  border-color: @secondary-color !important;

  &:hover,
  &:focus {
    color: @white-color;
    background-color: @secondary-color !important;
    border-color: @secondary-color !important;
  }

  &.disabled,
  &:disabled {
    background-color: @secondary-color !important;
    border-color: @secondary-color !important;
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    color: @white-color;
    background-color: @secondary-color !important;
    border-color: @secondary-color !important;
  }
}

.btn-success,
a.btn-success {
  color: @white-color !important;
  background-color: @success-color !important;
  border-color: @success-color !important;

  &:hover,
  &:focus {
    color: @white-color !important;
    background-color: darken(@success-color, 10%) !important;
    border-color: darken(@success-color, 10%) !important;
  }

  &.disabled,
  &:disabled {
    background-color: @success-color !important;
    border-color: @success-color !important;
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    color: @white-color !important;
    background-color: darken(@success-color, 10%) !important;
    border-color: darken(@success-color, 10%) !important;
  }
}

.btn-success-dark,
a.btn-success-dark {
  color: @white-color !important;
  background-color: darken(@success-color, 15%) !important;
  border-color: darken(@success-color, 15%) !important;

  &:hover,
  &:focus {
    color: @white-color !important;
    background-color: darken(@success-color, 20%) !important;
    border-color: darken(@success-color, 20%) !important;
  }

  &.disabled,
  &:disabled {
    background-color: @success-color !important;
    border-color: @success-color !important;
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    color: @white-color !important;
    background-color: darken(@success-color, 20%) !important;
    border-color: darken(@success-color, 20%) !important;
  }
}

.btn-info,
a.btn-info {
  color: @white-color !important;
  background-color: @info-color !important;
  border-color: @info-color !important;

  &:hover,
  &:focus {
    color: @white-color !important;
    background-color: darken(@info-color, 10%) !important;
    border-color: darken(@info-color, 10%) !important;
  }

  &.disabled,
  &:disabled {
    background-color: @info-color !important;
    border-color: @info-color !important;
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    color: @white-color !important;
    background-color: darken(@info-color, 10%) !important;
    border-color: darken(@info-color, 10%) !important;
  }
}

.btn-warning,
a.btn-warning {
  color: @white-color !important;
  background-color: @warning-color !important;
  border-color: @warning-color !important;

  &:hover,
  &:focus {
    color: @white-color !important;
    background-color: darken(@warning-color, 10%) !important;
    border-color: darken(@warning-color, 10%) !important;
  }

  &.disabled,
  &:disabled {
    background-color: @warning-color !important;
    border-color: @warning-color !important;
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    color: @white-color !important;
    background-color: darken(@warning-color, 10%) !important;
    border-color: darken(@warning-color, 10%) !important;
  }
}

.btn-yellow,
a.btn-yellow {
  color: @grey-9 !important;
  background-color: @yellow-color !important;
  border-color: @yellow-color !important;

  &:hover,
  &:focus {
    color: @white-color !important;
    background-color: darken(@yellow-color, 10%) !important;
    border-color: darken(@yellow-color, 10%) !important;
  }

  &.disabled,
  &:disabled {
    background-color: @yellow-color !important;
    border-color: @yellow-color !important;
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    color: @white-color !important;
    background-color: darken(@yellow-color, 10%) !important;
    border-color: darken(@yellow-color, 10%) !important;
  }
}

.btn-danger,
a.btn-danger,
.btn-red,
a.btn-red {
  color: @white-color !important;
  background-color: @danger-color !important;
  border-color: @danger-color !important;

  &:hover,
  &:focus {
    color: @white-color !important;
    background-color: darken(@danger-color, 10%) !important;
    border-color: darken(@danger-color, 10%) !important;
  }

  &.disabled,
  &:disabled {
    background-color: @danger-color !important;
    border-color: @danger-color !important;
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    color: @white-color !important;
    background-color: darken(@danger-color, 10%) !important;
    border-color: darken(@danger-color, 10%) !important;
  }
}

.btn-light,
a.btn-light {
  color: @grey-7 !important;
  background-color: @grey-4 !important;
  border-color: @grey-4 !important;

  &:hover,
  &:focus {
    color: @grey-7 !important;
    background-color: @grey-5 !important;
    border-color: @grey-5 !important;
  }

  &.disabled,
  &:disabled {
    background-color: @grey-3 !important;
    border-color: @grey-3 !important;
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    color: @grey-9 !important;
    background-color: @grey-4 !important;
    border-color: @grey-4 !important;
  }
}

.btn-dark,
a.btn-dark {
  color: @white-color !important;
  background-color: @dark-color !important;
  border-color: @dark-color !important;

  &:hover,
  &:focus {
    color: @white-color !important;
    background-color: darken(@dark-color, 10%) !important;
    border-color: darken(@dark-color, 10%) !important;
  }

  &.disabled,
  &:disabled {
    background-color: @dark-color !important;
    border-color: @dark-color !important;
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    color: @white-color !important;
    background-color: darken(@dark-color, 10%) !important;
    border-color: darken(@dark-color, 10%) !important;
  }
}

.btn-purple,
a.btn-purple {
  color: @white-color !important;
  background-color: @purple-color !important;
  border-color: @purple-color !important;

  &:hover,
  &:focus {
    color: @white-color !important;
    background-color: darken(@purple-color, 10%) !important;
    border-color: darken(@purple-color, 10%) !important;
  }

  &.disabled,
  &:disabled {
    background-color: @purple-color !important;
    border-color: @purple-color !important;
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    color: @white-color !important;
    background-color: darken(@purple-color, 10%) !important;
    border-color: darken(@purple-color, 10%) !important;
  }
}

.btn-sepia,
a.btn-sepia {
  color: @white-color !important;
  background-color: @sepia-color !important;
  border-color: @sepia-color !important;

  &:hover,
  &:focus {
    color: @white-color !important;
    background-color: darken(@sepia-color, 15%) !important;
    border-color: darken(@sepia-color, 15%) !important;
  }

  &.disabled,
  &:disabled {
    background-color: @sepia-color !important;
    border-color: @sepia-color !important;
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    color: @white-color !important;
    background-color: darken(@sepia-color, 15%) !important;
    border-color: darken(@sepia-color, 15%) !important;
  }
}

.btn-geekblue,
a.btn-geekblue {
  color: @white-color !important;
  background-color: @geekblue-8 !important;
  border-color: @geekblue-8 !important;

  &:hover,
  &:focus {
    color: @white-color !important;
    background-color: darken(@geekblue-8, 10%) !important;
    border-color: darken(@geekblue-8, 10%) !important;
  }

  &.disabled,
  &:disabled {
    background-color: @geekblue-8 !important;
    border-color: @geekblue-8 !important;
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    color: @white-color !important;
    background-color: darken(@geekblue-8, 15%) !important;
    border-color: darken(@geekblue-8, 15%) !important;
  }
}

.btn-cyan,
a.btn-cyan {
  color: @white-color !important;
  background-color: @cyan-color !important;
  border-color: @cyan-color !important;

  &:hover,
  &:focus {
    color: @white-color !important;
    background-color: darken(@cyan-color, 10%) !important;
    border-color: darken(@cyan-color, 10%) !important;
  }

  &.disabled,
  &:disabled {
    background-color: @cyan-color !important;
    border-color: @cyan-color !important;
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    color: @white-color !important;
    background-color: darken(@cyan-color, 12%) !important;
    border-color: darken(@cyan-color, 12%) !important;
  }
}

.btn-white,
a.btn-white {
  color: @text-color !important;
  background-color: @white-color !important;
  border-color: @white-color !important;

  &:hover,
  &:focus {
    color: @text-color !important;
    background-color: darken(@white-color, 10%) !important;
    border-color: darken(@white-color, 10%) !important;
  }

  &.disabled,
  &:disabled {
    background-color: @white-color !important;
    border-color: @white-color !important;
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    color: @text-color !important;
    background-color: darken(@white-color, 10%) !important;
    border-color: darken(@white-color, 10%) !important;
  }
}

.btn-orange,
a.btn-orange {
  color: @white-color !important;
  background-color: @orange-color !important;
  border-color: @orange-color !important;

  &:hover,
  &:focus {
    color: @white-color !important;
    background-color: darken(@orange-color, 10%) !important;
    border-color: darken(@orange-color, 10%) !important;
  }

  &.disabled,
  &:disabled {
    background-color: @orange-color !important;
    border-color: @orange-color !important;
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    color: @white-color !important;
    background-color: darken(@orange-color, 12%) !important;
    border-color: darken(@orange-color, 12%) !important;
  }
}

//Btn Outline
.btn-outline-primary,
a.btn-outline-primary {
  color: @primary-color !important;
  background-color: transparent !important;
  border-color: @primary-color !important;

  &:hover,
  &:focus {
    color: @white-color !important;
    background-color: @primary-color !important;
    border-color: @primary-color !important;
  }

  &.disabled,
  &:disabled {
    color: @primary-color !important;
    background-color: transparent !important;
  }
}

.btn-outline-secondary,
a.btn-outline-secondary {
  color: @secondary-color !important;
  background-color: transparent !important;
  border-color: @secondary-color !important;

  &:hover,
  &:focus {
    color: @white-color !important;
    background-color: @secondary-color !important;
    border-color: @secondary-color !important;
  }

  &.disabled,
  &:disabled {
    color: @secondary-color !important;
    background-color: transparent !important;
  }
}

.btn-outline-success,
a.btn-outline-success {
  color: @success-color !important;
  background-color: transparent !important;
  border-color: @success-color !important;

  &:hover,
  &:focus {
    color: @white-color !important;
    background-color: @success-color !important;
    border-color: @success-color !important;
  }

  &.disabled,
  &:disabled {
    color: @success-color !important;
    background-color: transparent !important;
  }
}

.btn-outline-success-dark,
a.btn-outline-success-dark {
  color: @success-color !important;
  background-color: transparent !important;
  border-color: darken(@success-color, 15%) !important;

  &:hover,
  &:focus {
    color: @white-color !important;
    background-color: darken(@success-color, 20%) !important;
    border-color: darken(@success-color, 20%) !important;
  }

  &.disabled,
  &:disabled {
    color: darken(@success-color, 20%) !important;
    background-color: transparent !important;
  }
}

.btn-outline-info,
a.btn-outline-info {
  color: @info-color !important;
  background-color: transparent !important;
  border-color: @info-color !important;

  &:hover,
  &:focus {
    color: @white-color !important;
    background-color: @info-color !important;
    border-color: @info-color !important;
  }

  &.disabled,
  &:disabled {
    color: @info-color !important;
    background-color: transparent !important;
  }
}

.btn-outline-warning,
a.btn-outline-warning {
  color: @warning-color !important;
  background-color: transparent !important;
  border-color: @warning-color !important;

  &:hover,
  &:focus {
    color: @grey-9 !important;
    background-color: @warning-color !important;
    border-color: @warning-color !important;
  }

  &.disabled,
  &:disabled {
    color: @warning-color !important;
    background-color: transparent !important;
  }
}

.btn-outline-danger,
a.btn-outline-danger,
.btn-outline-red,
a.btn-outline-red {
  color: @danger-color !important;
  background-color: transparent !important;
  border-color: @danger-color !important;

  &:hover,
  &:focus {
    color: @white-color !important;
    background-color: @danger-color !important;
    border-color: @danger-color !important;
  }

  &.disabled,
  &:disabled {
    color: @danger-color !important;
    background-color: transparent !important;
  }
}

.btn-outline-light,
a.btn-outline-light {
  color: @grey-7 !important;
  background-color: transparent !important;
  border-color: @grey-5 !important;

  &:hover,
  &:focus {
    color: @grey-7 !important;
    background-color: @grey-5 !important;
    border-color: @grey-5 !important;
  }

  &.disabled,
  &:disabled {
    color: @grey-4 !important;
    background-color: transparent !important;
  }
}

.btn-outline-dark,
a.btn-outline-dark {
  color: @grey-9 !important;
  background-color: transparent !important;
  border-color: @grey-9 !important;

  &:hover,
  &:focus {
    color: @white-color !important;
    background-color: @grey-9 !important;
    border-color: @grey-9 !important;
  }

  &.disabled,
  &:disabled {
    color: @grey-9 !important;
    background-color: transparent !important;
  }
}

.btn-outline-sepia,
a.btn-outline-sepia {
  color: @white-color !important;
  background-color: transparent !important;
  border-color: @sepia-color !important;

  &:hover,
  &:focus {
    color: @white-color !important;
    background-color: @sepia-color !important;
    border-color: @sepia-color !important;
  }

  &.disabled,
  &:disabled {
    color: @sepia-color !important;
    background-color: transparent !important;
  }
}

.btn-outline-geekblue,
a.btn-outline-geekblue {
  color: @white-color !important;
  background-color: transparent !important;
  border-color: @geekblue-10 !important;

  &:hover,
  &:focus {
    color: @white-color !important;
    background-color: @geekblue-10 !important;
    border-color: @geekblue-10 !important;
  }

  &.disabled,
  &:disabled {
    color: @geekblue-6 !important;
    background-color: transparent !important;
  }
}

.btn-outline-orange,
a.btn-outline-orange {
  color: @white-color !important;
  background-color: transparent !important;
  border-color: @orange-color !important;

  &:hover,
  &:focus {
    color: @white-color !important;
    background-color: @orange-color !important;
    border-color: @orange-color !important;
  }

  &.disabled,
  &:disabled {
    color: @orange-color !important;
    background-color: transparent !important;
  }
}

// Badge ColorPicker
.badge-primary {
  background-color: @primary-color !important;
}

.badge-primary-light {
  background-color: @primary-4 !important;
}

.badge-secondary {
  background-color: @secondary-color !important;
}

.badge-success {
  background-color: @success-color !important;
}

.badge-success-dark {
  background-color: darken(@success-color, 15%) !important;
}

.badge-info {
  background-color: @info-color !important;
}

.badge-warning {
  background-color: @warning-color !important;
}

.badge-danger {
  background-color: @danger-color !important;
}

.badge-light {
  background-color: @grey-4 !important;
}

.badge-dark {
  background-color: @black-color !important;
}

.badge-white {
  background-color: @white-color !important;
}

.badge-grey {
  background-color: @grey-6 !important;
}

.badge-light-grey {
  background-color: @grey-5 !important;
}

.badge-pink {
  background-color: @pink-color !important;
}

.badge-green {
  background-color: @green-color !important;
}

.badge-green-light {
  background-color: lighten(@green-color, 40%) !important;
}

.badge-red {
  background-color: @red-color !important;
}

.badge-amber {
  background-color: @amber-color !important;
}

.badge-amber-light {
  background-color: lighten(@amber-color, 30%) !important;
}

.badge-blue {
  background-color: @blue-color !important;
}

.badge-light-blue {
  background-color: lighten(@blue-color, 5%) !important;
}

.badge-indigo {
  background-color: @indigo-color !important;
}

.badge-purple {
  background-color: @purple-color !important;
}

.badge-orange {
  background-color: @orange-color !important;
}

.badge-yellow {
  background-color: @yellow-color !important;
}

.badge-teal {
  background-color: @teal-color !important;
}

.badge-cyan {
  background-color: @cyan-color !important;
}

.badge-sepia {
  background-color: @sepia-color !important;
}

.badge-geekblue {
  background-color: @geekblue-10 !important;
}







