.appointmentPopup{
    .ant-modal-body{background:#F4F4F4;}
    .appointmentList{
        display:flex; padding: 10px 7px; background:@white-color;
        img{height: 70px; width: 70px; border-radius:50%; margin-right: 15px;}
        p{margin-bottom: 0;
            &:not(:last-child){margin-bottom: 5px;}
        }
        &:not(:last-child){
            margin-bottom: 5px; 
        }
        @media screen and (max-width: @screen-sm-max) {
            img{height:55px; width:55px;}
        }
        @media screen and (max-width: @screen-xs-max) {
            img{height:45px; width:45px; margin-right:10px;}
        }
    }
}