.ant-form-item {
    .ant-select {
        .ant-select-selector {
            border-radius: 0;
        }

        &-lg {
            font-size  : 14px;
          
            font-family: @font-md;
        }
    }

    .ant-input-group-addon {
        border-radius: 0;
    }
}

.ant-select-dropdown {
    border-radius: 0;
}
.ant-select-item-option{
    &-selected{
        &:not(.ant-select-item-option-disabled){
            font-weight:500; background:@secondary-light-color;
            .ant-select-item-option-state{color:@secondary-color;}
        }
    }
    &-active{
        &:not(.ant-select-item-option-disabled){
            background:@secondary-light-color;
        }
    }
}
.ant-select-multiple{
    &.ant-select-lg{
        .ant-select-selection-item{align-items: center;}
    }
}