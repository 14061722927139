.scheduleModal{
    .selectDay{
		&__list{
			&:not(:last-child){
				margin-right: 15px;
			}
            .ant-checkbox-wrapper{
                height: 50px; width: 50px; border-radius:50%; background:#F7F8F9; display: flex; justify-content:center; align-items: center;
                color:#737374; font-size: 14px; cursor:pointer;
                &.ant-checkbox-wrapper-checked{
                    color: @white-color; background:@secondary-color; border-color: @secondary-color;
                }
                .ant-checkbox{display: none;
                    + * {padding: 0;}
                }
            }
            @media screen and (max-width: @screen-sm-max) {
                &:not(:last-child){ margin-right: 10px;} 
                .ant-checkbox-wrapper{
                    height: 40px; width: 40px; font-size: 13px;

                }
            }
            @media screen and (max-width: @screen-xs-max) {
                &:not(:last-child){ margin-right: 5px;} 
                .ant-checkbox-wrapper{
                    height: 35px; width: 35px;

                }
            }
		}
	}
}