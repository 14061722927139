.addPage{ margin-bottom: 40px;
    border:1px solid #dbdfea; background:#fff;
    padding: 25px; border-radius:4px;
    h3{margin-bottom: 25px; margin-top: 10px; font-size:20px; font-weight:600;}
    &--accordian{
        padding: 0; border: 0; background:transparent; box-shadow:none;
        .ant-collapse{
            border-radius:0; border:0; 
            .ant-collapse-item{ box-shadow:0 1px 5px 0 rgba(0,0,0,0.12);
                border-bottom:1px solid rgba(0, 0, 0, 0.05);
                .ant-collapse-header{
                    font-weight:600; font-size: 16px; color:#000; background: #fff;  
                    padding: 15px 20px;
                    .ant-collapse-arrow{margin-right: 0; margin-top: 4px; float:right; margin-left: 12px;}
                }
                &:last-child{border-radius: 0;
                    .ant-collapse-header{border-radius: 0;}
                }
                .ant-collapse-content-box{padding: 20px;}
                &.ant-collapse-item-active{
                    margin: 20px 0;
                }
            }
        }
        .formRow{
            padding: 15px; background: #fff; box-shadow:0 1px 5px 0 rgba(0,0,0,0.12);
        }
    }
    &--step{
        padding: 0; border: 0; background:transparent; box-shadow:none; max-width:900px; margin: 0 auto 40px;
        .ant-steps{ margin-bottom: 40px;
            .ant-steps-item{
                flex:1 1 33.33%; max-width:33.33%; position: relative; overflow: visible;
                .ant-steps-item-icon, .ant-steps-item-content{display: block; text-align:center;}
                .ant-steps-item-icon{margin:0 auto 10px;}
                .ant-steps-item-title{position: static; padding: 0; font-size:16px; font-weight:600;
                    &:after{width: 50%; left: 75%;}
                }
                &.ant-steps-item-active,&.ant-steps-item-finish{
                    .ant-steps-item-icon{background:@secondary-color; border-color:@secondary-color;}
                    .ant-steps-item-title{color:@secondary-color;}
                    .ant-steps-item-container{
                        &:hover{
                            .ant-steps-item-icon{
                                .ant-steps-icon{color:@white-color !important;}
                            }
                        }
                    }
                }
                &.ant-steps-item-finish{
                    .ant-steps-icon{color:@white-color !important;}
                    .ant-steps-item-title{
                        &:after{background:@secondary-color;}
                    }
                }
                .ant-steps-item-container{
                    &:hover{
                        .ant-steps-item-title{color:@secondary-color !important;}
                        .ant-steps-item-icon{border-color:@secondary-color !important;
                            .ant-steps-icon{color:@secondary-color !important;}
                        }
                    }
                }
            }
            &.twoStep {
                .ant-steps-item{flex:1 1 50%; max-width:50%;}
            }
              
        }
        .steps-content{
            background:#fff; box-shadow:0 1px 5px 0 rgba(0,0,0,0.12); padding: 25px 25px 0; min-height:550px;
        }
        .steps-action{
            background:#fff; box-shadow:0 2px 5px 0 rgba(0,0,0,0.05); padding:15px 25px 25px; text-align: center;
        }
        .ant-checkbox-group{margin-bottom: 15px;}
        h3{margin-top: 0; margin-bottom: 20px;}
    }
    .upload{
        margin:0 auto 30px; position: relative; display: inline-block;
        &__img {
            height: 110px; width: 110px; border-radius: 50%; position: relative; overflow: hidden;
            border: 2px solid @secondary-color;
            img{height: 100%; width: 100%;}
        }
        label {
            height: 30px; width: 30px; text-align: center; background: @secondary-color;
            color: #fff;position: absolute;
            border-radius: 50%; font-size: 14px;
            line-height: 31px; right: 0; bottom: 5px;
            cursor: pointer; box-shadow: 0 5px 10px rgba(0,0,0,0.3);
            input{display: none;}
        }
    }
    .ant-form-item-control{
        .ant-checkbox-wrapper{
            + .ant-checkbox-wrapper{margin-left: 0;}
            &:not(:last-child){
                margin-right: 20px; margin-bottom: 15px;
            }
            .ant-checkbox{
                + span{padding-right: 0; padding-left: 12px;}
            }
        }
    }
}