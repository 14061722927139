.callingMessage{
    position: fixed; left: 0; top: 0; height: 100%; width: 100%; z-index: 12;
    &__overlay{   
        position: fixed;
        top: 0; left: 0; z-index:101; width: 100vw;
        height: 100vh; background-color: rgba(@black-color,0.5);
    }
    &__content{
        position: absolute; left: 50%; top: 50%; transform:translate(-50%,-50%); z-index: 102; background: @white-color; border-radius:5px; width: 100%; max-width:400px;
    }
    &__head{
        padding: 20px;display: flex; justify-content:space-between; background:#038fde;
        a{color:@white-color; font-size:20px; line-height: 0;
            &:hover{color:@layout-header-background-dark;}
        }
        h4{margin-bottom: 0; color:@white-color;}
    }
    &__body{padding: 20px;
        text-align: center;
        p{font-size: 18px;}
    }
    &__bottom{
        padding:0 20px 20px; text-align: center;
        .ant-btn{
            min-width:110px;
            + .ant-btn{margin-left: 15px;}
        }
    }
}