.questionnaire{ margin-bottom: 30px;
    .commonQuestion{
        padding: 25px 30px;
        h5{font-weight:600; font-size:16px;}
        &__right{margin-left: 15px;}
        &:not(:last-child){margin-bottom: 15px;}
    }
    @media screen and (max-width: @screen-lg-max) {
        .commonQuestion{
            padding: 20px;
            h5{font-size:15px;}
        }
    }
    @media screen and (max-width: @screen-sm-max) {
        margin-bottom:20px;
        .commonQuestion{
            padding: 15px;
            h5{font-size:14px;}
            &:not(:last-child){margin-bottom: 10px;}
        }
    }
}