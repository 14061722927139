.docorList  {
    .sub-title{
        font-size: 16px;
        font-family: @font-sm;
        margin-bottom: 15px;
    }
    .searchBtn{
        margin-top: 27px;
    }
    .headRow{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        &-right{
            margin-left: auto;
            .list-inline{
                margin-bottom: 0;
                &-item{
                    margin-bottom: 15px; vertical-align: top;
                    &-sort{
                        width: 150px;
                        .ant-select{
                            width: 100%;
                        }
                    }
                    .ant-select-selector,.ant-input,.ant-btn{border-radius:0 !important;}
                }
            }
        }
    }
    .appointments{
        margin-bottom: 30px;
        &-doctors{
            box-shadow: none;
        }
        .appointmentBox{
            &__top{
                span{
                    display: inline-block;
                    margin-bottom: 10px;
                }
            }
            .consultation{
                margin-top: 0;
            }
        }
    }
    @media screen and (max-width: @screen-md-max) {
        margin-bottom: 20px;
        .appointments{margin-bottom: 20px;}
    }
    @media screen and (max-width: @screen-xs-max) {
        .searchBtn{
            margin-top:0px;
        }
    }
}