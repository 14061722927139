.addPagenew {
    margin-bottom: 40px;
    border       : 1px solid #dbdfea;
    background   : #fff;   
    padding      : 25px;
    border-radius: 4px;
    h3 {
        margin-bottom: 25px;
        margin-top   : 10px;
        font-size    : 20px;
        font-family: @font-sm;
    } 
    &-inner{
        display: flex;
        min-height: calc(100vh - 360px);
    } 
    &--step {
        padding   : 0;
        border    : 0;
        background: transparent;
        box-shadow: none;
        max-width : 900px;
        margin    : 0 auto 40px;
        display: flex;       
        flex-direction: column;
        .ant-steps {
            margin-bottom: 40px;
            .ant-steps-item {
                flex     : 1 1 33.33%;
                max-width: 33.33%;
                position : relative;
                overflow : visible;

                .ant-steps-item-icon,
                .ant-steps-item-content {
                    display   : block;
                    text-align: center;
                }

                .ant-steps-item-icon {
                    margin: 0 auto 10px;
                }

                .ant-steps-item-title {
                    position   : static;
                    padding    : 0;
                    font-size  : 16px;
                    font-weight: 600;

                    &:after {
                        width: 50%;
                        left : 75%;
                    }
                }

                &.ant-steps-item-active,
                &.ant-steps-item-finish {
                    .ant-steps-item-icon {
                        background  :@layout-header-background-dark;
                        border-color:@layout-header-background-dark;
                    }

                    .ant-steps-item-title {
                        color: @secondary-color;
                    }

                    .ant-steps-item-container {
                        &:hover {
                            .ant-steps-item-icon {
                                .ant-steps-icon {
                                    color: @white-color  !important;
                                }
                            }
                        }
                    }
                }

                &.ant-steps-item-finish {
                    .ant-steps-icon {
                        color: @white-color  !important;
                    }

                    .ant-steps-item-title {
                        &:after {
                            background: @secondary-color;
                        }
                    }
                }

                .ant-steps-item-container {
                    &:hover {
                        .ant-steps-item-title {
                            color: @secondary-color  !important;
                        }

                        .ant-steps-item-icon {
                            border-color: @layout-header-background-dark  !important;

                            .ant-steps-icon {
                                color:@layout-header-background-dark !important;
                            }
                        }
                    }
                }
            }

            &.twoStep {
                .ant-steps-item {
                    flex     : 1 1 50%;
                    max-width: 50%;
                }
            }
        }

        .steps-content {
            background: #fff;
            box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12);
            padding   : 25px 25px 0;
            flex:1 1;   
            form{
                display: flex;
                flex-direction: column;
                min-height:100%;
            }
            .anticon{
                &.anticon-close{display: flex; align-items:center; justify-content:center;}
            }        
        }

        .steps-action {
            padding   : 15px 25px 25px;
            text-align: center;    margin-top: auto;
            .ant-btn{
                &+.ant-btn{
                    margin-left:15px;
                }
            }
        }

        .ant-checkbox-group {
            margin-bottom: 15px;
        }

        h3 {
            margin-top   : 0;
            margin-bottom: 30px;
        }
        h4{
            font-family: @font-md;
            margin-bottom:20px;
        }
    }

    .upload {
        margin-bottom  :30px;
        position: relative;
        display : inline-block;

        &__img {
            height: 110px;
            width : 110px;
            position: relative;
            overflow: hidden;
            border  : 2px solid @secondary-color;
            img {
                height    : 100%;
                width     : 100%;
                object-fit: cover;
            }
        }

        label {
            height       : 30px;width        : 30px;text-align   : center;background   : @secondary-color; color        : #fff;
            position     : absolute;
            border-radius: 50%;
            font-size    : 14px;
            line-height  : 31px;
            right        : 0;
            bottom       : 5px;
            cursor       : pointer;
            box-shadow   : 0 5px 10px rgba(0, 0, 0, 0.3);

            input {
                display: none;
            }
        }
    }

    .ant-form-item-control {
        .form-check{
            display: flex;
            flex-wrap: wrap;
            &.autoSet{
                .ant-checkbox-wrapper {
                    max-width: inherit; flex: inherit;
                }
            }
            .ant-checkbox-group{display: flex; flex-wrap: wrap; width: 100%;}
        }
        .ant-checkbox-wrapper {
            font-family:@font-md;
            flex:0 0 33.333%; max-width:33.333%; margin-right: 15px;


            &:not(:last-child) {               
                margin-bottom: 15px;
            }

            .ant-checkbox {
                +span {
                    padding-right: 0;
                    padding-left : 12px;
                }
            }
            &.mw-100{flex:1 1 100%;}
        }
        .ant-checkbox-group{width:100%;}
    }
    @media screen and (max-width: @screen-lg-max) {
        &--step {
            margin-bottom: 35px;
            h3{margin-bottom: 20px;}
            .ant-steps{margin-bottom: 35px;
                .ant-steps-item {
                    .ant-steps-item-title {
                        font-size  : 15px; white-space:normal; line-height:normal;
                    }    
                }
            }
            .steps-content {
                padding : 25px 20px 0;        
            }
            .steps-action {
                padding : 15px 20px 25px;
            }
        }
    }
    @media screen and (max-width: @screen-md-max) {
        h3{font-size:18px;}
        &--step {margin-bottom: 20px;
            h3{margin-bottom:15px;}
            .ant-steps{margin-bottom:25px;}
        }
        .upload {
            margin-bottom:20px;
        }
    }
    @media screen and (max-width: @screen-sm-max) {
        h3{font-size:16px;}
        &--step {margin-bottom: 20px;
            h3{margin-bottom:15px;}
            .ant-steps{margin-bottom:15px;
                .ant-steps-item {
                    .ant-steps-item-title {font-size:0;
                        &:after{left: 80%;}
                    }    
                }
            }
            .steps-content { padding : 20px 15px 0;}
            .steps-action {padding : 10px 15px 20px;}
        }
        .upload {
            margin-bottom :10px;
            &__img {
                height: 100px; width : 100px; border  : 1px solid @secondary-color;}
        }
        .ant-form-item-control {
            .form-check{
                margin:0 -5px;
            }
            .ant-checkbox-wrapper {
                padding:0 5px;
            }
        }
    }
    @media screen and (max-width: @screen-xs-max) {
        .ant-form-item-control {
            .ant-checkbox-wrapper {
                flex:0 0 50%;
                max-width:50%;
            }
        }
    }
    @media (max-width:375px) {
        .ant-form-item-control {
            .ant-checkbox-wrapper {
                flex:0 0 100%;
                max-width:100%;
            }
        }
    }
    .licenseTag {
        background: #9d9af645;display: inline-flex; width: auto; padding: 8px 15px;color: @layout-header-background-dark; align-items: center; margin-bottom: 15px;
        .closeTag{
            margin-left:10px; color:@layout-header-background-dark; font-size: 14px; border: 1px solid @layout-header-background-dark;border-radius: 50%;height: 22px;
            width: 22px;line-height: 20px; text-align: center;
        }
        &:not(:last-child){margin-right: 20px;}
    }
}